import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from 'reactstrap/lib/Container';
import Autosuggest from 'react-autosuggest';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Loader } from '../../../global/Loader';
import { Select } from '../../../global/Forms/Select';
import { Auth } from '../../../../actions/Auth';
import Button from '../../../global/Button';
import { InputField } from '../../../global/Forms';
import Company  from './company';
import { Errorbar } from '../../../global/errorbar';
import Axios from 'axios';
import Link from 'react-router-dom/Link';
import { uploadFile, getFullName, getFiles } from '../../../../actions';
import FilterComponent from '../../../global/filter-component';
import Aux from '../../../global/AuxComp';
import StudentTable from './StudentTable.js';
import { Confirmation } from '../../../global/confirmation';
import { CONSTANTS } from '../../../../constants';
import PaginationComponent from '../../../global/PaginationComponent';
import { PermissionDenied } from '../../../global/PermissionDenied';
import HelpSection from "../../helpSection/student_section";
import moment from 'moment';
import { DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, UncontrolledDropdown } from 'reactstrap';
import { SingleDatePicker } from '../../../global/Forms/DatePicker';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/information.svg';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';
import PopoverBody from 'reactstrap/lib/PopoverBody';
import UncontrolledCollapse from 'reactstrap/lib/UncontrolledCollapse';
import { ReactComponent as AttachmentLogo } from '../../../../assets/icons/attachment.svg';

class Process extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
    this.allCompanies = []
    this.batches = {}
    this.allStudents = []
  }

  getInitialState = () => {
    return {
      init: true,
      processList: [],
      ptList: [],
      addProcessModal: false,
      editProcess: false,
      selectedProcess: null,
      isLoading: false,
      fetchStudentModal: false,
      deleteModal: false,
      deleteprocessCompanyModal: false,
      companies: [],
      applications: [],
      selectedApplication: "",
      selectedCompany: "",
      addCompanyModal: false,
      addStudentModal: false,
      companySearch: '',
      shortlistVerification: false,
      errorbar: false,
      message: '',
      query: "",
      batchList: {},
      depList: {},
      groupList: {},
      studentFilters: {
        batches: [],
        departments: [],
        groups: [],
      },
      processCompanyID: null,
      processStudent: [],
      companyFilters: {
        query: "",
        sort: ""
      },
      pagination: {
        currentPage: 0,
        rowsPerPage: 10,
      },
      isConfirmDeleteStudent: false,
      deleteStudentId: 0,
      noPermissions: false,
      selectedCount: 0,
      tabs : {
        'All' : [],
        'Not Open' : [],
        'Opened' : [],
        'Closed' : [],
        'Completed' : [],
      },
      plublishedApplications : [],
      closedApplications : [],
      selectedTab : 'All',
      autoSuggestCompany : null,
      filterCompany: {},
      classificationDetails: null
    }
  }

  componentDidMount() {
    let { hasPermission } = this.props.role
    if (!hasPermission.placementsTab || (hasPermission.placementsTab && !hasPermission.placementsTab.permission_access.read)) {
      if (hasPermission.reportPlacementTeamPerformance && hasPermission.reportPlacementTeamPerformance.permission_access.read)
        this.props.history.push("/pt/performance")
      else
        this.setState({ noPermissions: true })
      return
    }
    this.init(this.props.role);
    this.getClassfication();
  }

  getClassfication =  async ()=>{
    let classificationDetails  = await Auth.classification.listClassificationDetails();
    this.setState({
      classificationDetails : classificationDetails.data
    })
  }
  init = async (role) => {
    let query = ((this.props.location['state']) && (this.props.location.state['query']))?this.props.location.state.query:"";
    this.setState({
      isLoading: true
    })
    try {
      let responseBatch = await Auth.Batch.getBatchDetail();
      this.setState({ batchList: responseBatch.data.reduce((prev, current) => { prev[current.batch_id] = current; return prev; }, {}) })
      let processResp = await Auth.Processes.getProcesses(role.instituteDetail.campus_id);
      let ptList = await Auth.Spoc.getUsers({ role_type: CONSTANTS.ROLE_TYPES_ID.PT, campus_id: role.instituteDetail.campus_id })
      let process = null;
      this.setState({
        processList: processResp.data,
        ptList: ptList.data
      })
      if (this.props.match.params.process_id) process = processResp.data.filter(p => p.process_id === parseInt(this.props.match.params.process_id))[0]
      if (!process && processResp.data[0]) process = processResp.data[0]
      if (process) await this.fetchProcessData(process)
      if(query !== '') this.handleFilterChange(query,'query')
    } catch (e) {
      this.setState({ processList: [] })
      console.log(e)
    }
    this.setState({
      isLoading: false,
      init: false
    })
  }

  toggleLoading = (show) => {
    this.setState({
      isLoading: show
    })
  }

  fetchProcessData = async (process) => {
    let { pagination } = this.state;
      pagination.currentPage = 0
    this.setState({
      isLoading: true,
      selectedProcess: process,
      pagination
    })
    await Axios.all([this.getCompanies(process.process_id), this.getApplications(process.process_id)])
    let res = await Auth.Processes.getProcessStudents(process.process_id);
    this.setState({ processStudent: res.data });
    this.props.history.push(`/pt/processes/${process.process_id}/detail`)
    this.setState({
      isLoading: false
    })
  }

  getCompanies = async (process_id) => {
    try {
      let companiesResp = await Auth.Processes.getCompaniesInProcess(process_id)
      companiesResp.data.forEach(co => {
        let meeting = { campus_company_id: co.campus_company_id }
        let params = new URLSearchParams();
        params.append("campus_company_id", co.campus_company_id)
        params.append("access_key", this.props.role.instituteDetail.access_key)
      })
      this.setState({
        companies: companiesResp.data
      })
    } catch (e) {
      console.log(e)
      this.setState({
        companies: []
      })
    }
  }

  handleTabSelection = ()=>{
    let {tabs , selectedTab} = this.state;
    let temp = tabs[selectedTab].map(item=>item);
    this.setState({applications : temp});
  }

  getApplications = async (process_id, status) => {
    let {selectedTab , tabs} = this.state;
    let tempTabs = {};
    tempTabs["All"] = [];
    tempTabs["Not Open"] = [];
    tempTabs["Opened"] = [];
    tempTabs["Application Closed"] = [];
    tempTabs['Recruitment Completed'] = [];
    try {
      let applicationsResp = await Auth.Processes.getApplicationsInProcess(process_id, status);
      if(applicationsResp.data.length){
        applicationsResp.data.map(apl=>{
          if(apl.is_recruitment_closed){
            tempTabs["Recruitment Completed"].push(apl);
            tempTabs["All"].push(apl);
          }
         else if(apl.publish_status==0){
            tempTabs["Not Open"].push(apl);
            tempTabs["All"].push(apl);
          }
          else if(apl.publish_status==1 && (moment(apl.application_deadline).diff(moment(),"milliseconds")>0)){
            tempTabs["Opened"].push(apl);
            tempTabs["All"].push(apl);
          }
          else if(apl.publish_status==1 && (moment(apl.application_deadline).diff(moment(),"milliseconds")<=0)){
            tempTabs["Application Closed"].push(apl);
            tempTabs["All"].push(apl);
          }
          else{
            tempTabs["All"].push(apl);
          }
        })
      }

      await this.setState({
        applications: applicationsResp.data,
        tabs : tempTabs
      })

      this.handleTabSelection();

    } catch (e) {
      console.log(e)
      this.setState({
        applications: []
      })
    }
  }

  toggleRm = async () => {
    let { shortlistVerification, selectedProcess } = this.state;
    this.setState({ isLoading: true, init: true })
    if (shortlistVerification) {
      await this.getApplications(selectedProcess.process_id)
      this.setState({
        shortlistVerification: false
      })
    } else {
      await this.getApplications(selectedProcess.process_id, 1)
      this.setState({
        shortlistVerification: true
      })
    }
    this.setState({ isLoading: false, init: false })
  }

  toggleApplication = (applicationId, company_id) => {
    this.setState({
      selectedApplication: applicationId !== this.state.selectedApplication ? applicationId : ""
    })
    if (this.state.selectedApplication !== applicationId) {
      this.toggleLoading(true)
      Auth.Processes.getApplicants(applicationId).then(resp => {
        this.handleDataChange({ application_id: applicationId, ...resp.data })
        this.toggleLoading(false)
      }).catch((err) => {
        this.toggleLoading(false)
      })
      if(company_id){
        Auth.Recruiter.Connection.getConnection({company_id, campus_id: this.props.role.instituteDetail.campus_id}).then(resp => {
          // if(resp.data.length){
          this.handleDataChange({ company_id, recruiter : resp.data.filter(o => !!o.connection)[0] })
          // }
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }

  toggleCompany = (companyId) => {
    this.setState({
      selectedCompany: companyId !== this.state.selectedCompany ? companyId : ""
    })
  }

  handleDataChange = (obj, isNew) => {
    console.log(obj)
    if (obj.company_id) {
      let { companies } = this.state;
      companies = companies.map(company => {
        if (company.company_id === obj.company_id) {
          company = { ...company, ...obj }
        }
        return company
      })
      this.setState({
        companies
      })
    } else if (obj.application_id) {
      let { applications } = this.state;
      if (isNew) {
        applications.push(obj)
      } else {
        applications = applications.map(application => {
          if (application.application_id == obj.application_id)
            application = { ...application, ...obj }
          return application
        })
      }
      this.setState({
        applications
      })
    } else if (obj.process_id) {
      let { processList } = this.state;
      processList = processList.map(process => {
        if (process.process_id === obj.process_id)
          process = { ...process, ...obj }
        return process
      })
      this.setState({
        processList
      })
    }
  }

  addProcess = async (e) => {
    e.preventDefault();
    let {addProcessModal, processList} = this.state;
    let formData = {
      process_name: addProcessModal.process_name,
      process_type: addProcessModal.process_type || Object.keys(CONSTANTS.PROCESS_TYPES)[0],
      deadline : addProcessModal.deadline && moment(addProcessModal.deadline).endOf("day").toDate(),
      payment: addProcessModal.payment || 0,
      campus_id: this.props.role.instituteDetail.campus_id,
      process_id : addProcessModal.process_id,
      file_id: addProcessModal.file_id
    }
    var isEdit =  !!addProcessModal.process_id;
    this.toggleLoading(true)
    if(e.target.policy_file && e.target.policy_file.files.length){
      let fileResp = await uploadFile(e.target.policy_file.files[0], `process/policies`);
      formData.file_id = fileResp.file_id;
      addProcessModal.file_id = formData.file_id;
    }
    var method = isEdit ? Auth.Processes.updateProcess : Auth.Processes.addProcess;
    await method(formData).then(resp => {
        if(isEdit) {
          processList = processList.map(process => {
            if(process.process_id == addProcessModal.process_id) process = addProcessModal;
            return process;
          });
        }
        else if(resp.data[0]){
          processList.push(resp.data[0])
          this.setState({applications: [], companies: [], processStudent: []});
        }
        this.setState({
          processList,
          addProcessModal: false,
          selectedProcess: isEdit ? addProcessModal:  resp.data[0]
        })
    }).catch(e => {
      console.log(e)
      this.toggleLoading(false);
    })
    this.toggleLoading(false);

  }
  fetchSelectedProcessFile = async () => {
    let {addProcessModal} = this.state;
    if(addProcessModal.file_id){
      this.toggleLoading(true);
      var files = await getFiles([addProcessModal.file_id]);
      addProcessModal.file = files[addProcessModal.file_id] ? files[addProcessModal.file_id][0] : {};
      this.setState({addProcessModal});
      this.toggleLoading(false);
    }
  }
  // deleteProcess = () => {
  //   Auth.Processes.deleteProcess({ process_id: this.state.selectedProcess.process_id }).then(async (resp) => {
  //     await this.init()
  //     this.setState({
  //       deleteModal: false
  //     })
  //   })
  // }

  mapCompanytoProcess = async (e) => {
    e.preventDefault();
    let { selectedProcess } = this.state;
    let formData = {
      process_id: selectedProcess.process_id,
      campus_company_id: []
    },
      checkList = e.target.campus_company_id;
    if (!RadioNodeList.prototype.isPrototypeOf(checkList)) {
      checkList = [checkList];
    };
    checkList.forEach(co => {
      if (co.checked) formData.campus_company_id.push(co.value)
    })
    this.setState({ isLoading: true })
    try {
      await Auth.Processes.mapCompaniesToProcess(formData)
      await this.fetchProcessData(selectedProcess)
      this.setState({ addCompanyModal: false })
    } catch (e) {
      console.log(e)
    }
    this.setState({ isLoading: false })
  }


  selectAllCompanies = e => {
    let form = e.target.closest("form");
    let { checked } = e.target,
      checkList = form.campus_company_id;
    if (!RadioNodeList.prototype.isPrototypeOf(checkList)) {
      checkList = [checkList];
    };
    checkList.forEach(co => {
      co.checked = checked
    })
  }

  onCompanyChange = (e) => {
    let form = e.target.closest("form"),
      allChecked = true,
      checkList = form.campus_company_id;
    if (!RadioNodeList.prototype.isPrototypeOf(checkList)) {
      checkList = [checkList];
    }
    checkList.forEach(co => {
      if (co.checked === false) {
        allChecked = false
      }
    })
    form.selectAll.checked = allChecked
  }


  filterStudents = (studentFilters, batchList, depList, groupList) => {
    let students = this.allStudents;
    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)

    this.setState({ batchList: batchList, depList: depList, groupList: groupList, selectedCount: students.length });
    this.setState(prevState => ({
      studentFilters: {
        ...prevState.studentFilters,
        batches: studentFilters.batches,
        departments: studentFilters.departments,
        groups: studentFilters.groups
      }
    }))
  }

  mapStudenttoProcess = async () => {
    let { studentFilters, selectedProcess, batchList, depList, groupList } = this.state;
    let students = this.allStudents;
    // let batchId = [];
    // let depId = [];
    // this.setState({ isLoading: true })
    // if (studentFilters.batches.length > 0) {
    //   studentFilters.batches.forEach(bt => {
    //     batchId.push(this.state.batchList[bt].batch_id)
    //   })
    // }
    // if (studentFilters.departments.length > 0) {
    //   studentFilters.departments.forEach(dept => {
    //     if (this.state.depList[dept]) {
    //       depId.push(this.state.depList[dept].degree_department_id)
    //     }
    //   })
    // }

    // if((batchId.length > 0) || (depId.length>0)){
    //   try {
    //       let res = await Auth.PtStudents.getBatchSpec(batchId,depId);
    //         let tempsts = [];
    //         res.data.forEach(batch => {
    //           batch.student_info && batch.student_info.forEach(student => {
    //             tempsts.push(student)
    //           })
    //         })
    //       students = tempsts;
    //     } catch (e) {
    //       console.log(e);
    //     }
    // }
    // if (studentFilters.groups.length > 0){
    //       let sts = []
    //       Object.keys(groupList).forEach(group =>{
    //           if(studentFilters.groups.indexOf(group)>-1){
    //             groupList[group].student_info.forEach(st=>{
    //                           sts.push(st)
    //               })
    //           }
    //       });
    //       students = sts;
    // }

    if (studentFilters.batches.length > 0)
      students = students.filter(st => studentFilters.batches.indexOf(st.batch_id.toString()) > -1)
    if (studentFilters.departments.length > 0)
      students = students.filter(st => studentFilters.departments.indexOf(st.batch_id + "-" + st.degree_department_id) > -1)
    if (studentFilters.groups.length > 0)
      students = students.filter(st => studentFilters.groups.indexOf(st.batch_id + "-" + st.degree_department_id + "-" + st.group_id) > -1)

    if (students.length < 1) {
      this.setState({ isLoading: false })
      this.setState({ errorbar: true, message: "No students selected" })
      return;
    }
    try {
      let formData = {
        process_id: selectedProcess.process_id,
        student_id: students.map(st => st.student_id)
      }
      this.setState({ isLoading: true })
      await Auth.Processes.mapStudentsToProcess(formData)
      let resp = await Auth.Processes.getProcessById(selectedProcess.process_id)
      let res = await Auth.Processes.getProcessStudents(selectedProcess.process_id);
      this.setState({ addStudentModal: false, selectedProcess: resp.data[0] })
      this.setState({ processStudent: res.data });
    } catch (e) {
      console.log(e);
    }
    this.setState({ isLoading: false })
  }

  handleFilterChange = (val, prop) => {
    let { companyFilters, pagination } = this.state;
    companyFilters[prop] = val;
    pagination.currentPage = 0
    this.setState({ companyFilters, pagination })
  }

  setCompany = async (value) => {
    //e.preventDefault();
    let { selectedProcess } = this.state;
    let formData = {
      process_id: selectedProcess.process_id,
      campus_company_id: [value.campus_company_id]
    }
    this.setState({ isLoading: true })
    try {
      await Auth.Processes.mapCompaniesToProcess(formData)
      await this.fetchProcessData(selectedProcess)
      this.setState({
        query: '',
        companySearch: ''
      })
    } catch (e) {
      console.log(e)
    }
    this.setState({ isLoading: false })
  }


  clearSuggestionList = () => {
  }



  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  autoSearch = (data) => {
    this.setState({ query: data.value })
  }

  deleteProcessCompany = () => {
    Auth.Processes.deleteProcessCompany({ process_company_id: this.state.processCompanyID }).then(async (resp) => {
      await this.init(this.props.role)
      this.setState({
        deleteprocessCompanyModal: false
      })
    })
  }

  confirmProcessCompany = (id) => {
    this.setState({
      processCompanyID: id,
      deleteprocessCompanyModal: true
    })
  }

  addStModal = async () => {
    this.setState({
      isLoading: true
    })
    this.setState({ addStudentModal: true })
    this.allStudents = []
    await Auth.PtStudents.getBatch().then(resp => {
      resp.data.forEach(batch => {
        batch.student_info.forEach(student => {
          this.allStudents.push(student)
        })
      })
    })
    this.setState({ isLoading: false, selectedCount: this.allStudents.length })

  }


  closebar = () => {
    this.setState({ errorbar: false });
  }

  addCoModal = async () => {
    this.setState({
      isLoading: true
    })
    this.setState({ addCompanyModal: true })
    try {
      let resp = await Auth.PtCompanies.getCompanies();
      this.allCompanies = resp.data;
      this.setState({ isLoading: false })
    }
    catch (e) {
      this.setState({ isLoading: false })
    }
  }
  onPageChange = (selectedPage) => {
    let { pagination } = this.state;
    pagination.currentPage = selectedPage - 1
    this.setState({ pagination })
  }

  confirmDeleteStudent = (student_id) => {
    this.setState({ isConfirmDeleteStudent: true, deleteStudentId: student_id })
  }
  toggleDeleteStudentConfirmation = () => {
    this.setState({ isConfirmDeleteStudent: false, deleteStudentId: 0 })
  }

  deletestudent = async () => {
    this.setState({ isLoading: true })
    var body = {
      process_id: this.state.selectedProcess.process_id,
      student_id: [this.state.deleteStudentId]
    }
    try {
      let resp = await Auth.Processes.removeStudentsFromProcess(body);
      let processStudent = this.state.processStudent;
      processStudent = processStudent.filter((student) => {
        return body.student_id.indexOf(student.student_id) === -1
      })
      this.toggleDeleteStudentConfirmation()
      this.setState({ isLoading: false, processStudent })
    }
    catch (e) {
      this.setState({ errorbar: true, message: "Something went wrong" })
      this.setState({ isLoading: false })
    }
  }

  handleFieldChange = (e, { newValue , method }) => {
      this.setState({
        companySearch : newValue
      });
    }

  archiveProcess = async process => {
    if(!window.confirm("Are you sure to Archive this placement drive ?")) return null;
    let {processList} = this.state;
    this.toggleLoading(true);
    let params = {
      process_id: process.process_id,
      archive_status: 1
    };
    try {
      await Auth.Processes.archiveUnarchiveProcess(params);
      if(processList.length > 1){
        processList.splice(processList.findIndex(p => p.process_id == process.process_id), 1);
        this.setState({processList});
        await this.fetchProcessData(processList[0]);
      }
      else{
        this.props.history.push(`/pt/processes`);
        this.init();
      }
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
    this.toggleLoading(false);
  };
  deleteProcess = async process => {
    if(!window.confirm("This action can't be undone, do you want to proceed?")) return;
    this.toggleLoading(true);
    let { processList } = this.state;
    try {
      await Auth.Processes.deleteProcess({ process_id: process.process_id });
      if(processList.length > 1){
        processList.splice(processList.findIndex(p => p.process_id == process.process_id), 1);
        this.setState({processList});
        await this.fetchProcessData(processList[0]);
      }
      else{
        this.props.history.push(`/pt/processes`);
        this.init();
      }
    } catch (e) {
      this.setState({errorbar : true});
      if(e.response && e.response.data){
        this.setState({message : e.response.data.error.toString()})
      }else{
        this.setState({message : "Something went wrong! Please try again"})
      }
    }
    this.toggleLoading(false);
  };
  render() {
    let { deleteprocessCompanyModal, companySearch, noPermissions, processStudent, selectedCount, query, init, processList, companyFilters, selectedProcess, isLoading, companies, applications, selectedApplication, selectedCompany, addProcessModal, editProcess, deleteModal, addCompanyModal, addStudentModal, studentFilters, ptList, shortlistVerification, depList, batchList, groupList, pagination , selectedTab , tabs , plublishedApplications , closedApplications , allApplications , autoSuggestCompany, filterCompany} = this.state;
    console.log(companies);
    if (noPermissions) {
      return (
        <PermissionDenied />
      )
    }
    const autoSuggestProps = {
      onChange: this.onChange,
      className: "tableinput r-nav__search mt-4",
    };
    let hasPermission = this.props.role.hasPermission
    var isShowOnlyAssignedCompany = hasPermission.showOnlyAssignedCompanies && hasPermission.showOnlyAssignedCompanies && hasPermission.showOnlyAssignedCompanies.permission_access.read;
        
    let companyData = this.allCompanies.filter(co => {
      if (companies.filter(c => c.campus_company_id === co.campus_company_id).length === 0) {
        let name = co.company_details.company_name || "";
        return name.toLowerCase().includes(query.toLowerCase())
      }
    });
    companies = companies.filter(company => {
      if(filterCompany.pt_member_id && filterCompany.pt_member_id != company.user_id) return false;
      if(filterCompany.classification_id && company.classifications.findIndex(x => x.classification_id == filterCompany.classification_id) === -1) return false;
      return true;
    })
    let  filteredCompanies = companies.filter((company) => {
        if (isShowOnlyAssignedCompany) return company.user_id === this.props.user.id;    
        let profileApplications ={};
          profileApplications = applications.reduce((prev, current) => {
            let temp = prev;
            if (company.process_company_id === current.process_company_id) {
              temp[current.process_company_profile_id] = current;
            }
            return temp;
          }, {})
          if(Object.keys(profileApplications).length===0 && (selectedTab=="Not Open" || selectedTab=="Opened" || selectedTab=="Closed"))
          return false

        let query = companyFilters.query.toLowerCase(),
          name = company.company_name || "",
          sector_name = company.sector_name || "",
          assignee_name = getFullName(company.user) || "",
          profileFilter = false,
          filter = (name.toLowerCase().search(query) > -1) || (sector_name.toLowerCase().search(query) > -1) || (assignee_name.toLowerCase().search(query) > -1)
        company.profile.forEach(pro => {
          if (profileApplications[pro.process_company_profile_id] && pro.role_name.toLowerCase().search(query) > -1) {
            profileFilter = true;
          }
        })
        if (!shortlistVerification || Object.keys(profileApplications).length > 0)
          if (filter || profileFilter)
            return true
      })
    return (
      <Container className="processes">
        <Loader show={isLoading} />
        {!init && <Aux>
          <Row className="align-items-center">
            <Col>
              <Row className="align-items-center mt-4 border-bottom">
                <Col xs={12}>

                  {processList.length !== 0 ? <Button className="mr-3" onClick={() => this.setState({ addProcessModal:  {} })} color="blue-3"><i className="fi flaticon-plus-1 r-text--sm"></i></Button> : null}

                  {
                    processList.map(process => {
                      return (
                        <span className="mr-3">
                          <Button className="my-3" key={process.process_id} onClick={() => this.fetchProcessData(process)} color={process.process_id == selectedProcess.process_id ? "blue-3" : "blue"}>{process.process_name}</Button>
                          {selectedProcess.process_id == process.process_id &&
                          <UncontrolledDropdown className="d-inline-block">
                            <DropdownToggle color={"link"} className="r-btn--link-blue" style={{fontSize: 18}} caret>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={() => this.setState({addProcessModal : process})}>Edit</DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem onClick={() => this.archiveProcess(process)}>Archive</DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem onClick={() => this.deleteProcess(process)}>Delete</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          }
                        </span>
                      )

                    })
                  }
                  {/* <Modal zIndex="99999" isOpen={this.state.modal} toggle={() => this.setState({ modal: !this.state.modal, selectedProcessID: null, selectedProcessName:null, selectedProcessType : null })}>
                    <ModalBody><span className="modal__title r-heading-6">Edit Process</span>
                      <div className="mt-3">
                        <label className="r-subheading-4">Process Name</label>
                          <InputField defaultValue={this.state.selectedProcessName} name="process_name" className="w-100 border-0" type="text" placeholder="Enter Process Name" onChange={this.SetNewProcess} value  ={this.state.updateProcess}/>
                        <label className="mt-2 r-subheading-4">Process Type</label>
                      <div>
                        {this.state.selectedProcessType && (
                          <select className="w-100 r-bg--grey-1 border-0 p-2" onChange={this.setNewProcessType} value={this.state.selectedProcessType}>
                            {Object.keys(CONSTANTS.PROCESS_TYPES).map(processType => (
                                <option value={processType}>
                                  {
                                    CONSTANTS.PROCESS_TYPES[
                                      processType
                                    ]
                                  }
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        <div className="text-right mt-3">
                          <Button type="submit" color="dark" onClick={this.updateProcess} className="r-text--sm r-btn--no-radius">Save</Button>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal> */}

                  <Modal zIndex="99999" onOpened={this.fetchSelectedProcessFile} isOpen={!!addProcessModal } toggle={() => this.setState({ addProcessModal: false, editProcess: false })}>
                    <ModalBody>
                      <span className="modal__title r-heading-6">{addProcessModal.process_id ? "Edit" : "Add"} Placement Drive</span>
                      <form className="mt-3" onSubmit={this.addProcess}>
                        <label className="r-subheading-4">Placement Drive Name<span className="text-danger">*</span></label>
                        <InputField value={addProcessModal.process_name || ""} onChange={(e) => {addProcessModal.process_name = e.target.value; this.setState({addProcessModal});}} required name="process_name" className="w-100" type="text" placeholder="Enter Placement Drive Name" />
                        <label className="mt-2 r-subheading-4">Placement Drive Type<span className="text-danger">*</span></label>
                        <Select name="process_type" value={addProcessModal.process_type || Object.keys(CONSTANTS.PROCESS_TYPES)[0]}  onChange={(e) => {addProcessModal.process_type = e.target.value; this.setState({addProcessModal});}}>
                          {
                            Object.keys(CONSTANTS.PROCESS_TYPES).map((processType) =>
                              <option key={processType} value={processType}>{CONSTANTS.PROCESS_TYPES[processType]}</option>
                            )
                          }
                        </Select>
                        <Button id="additional_option_1" type="button" className="my-3" style={{fontSize: 12}} color="link-blue">Additional Options</Button>
                        <UncontrolledCollapse toggler="#additional_option_1">
                          <label className="r-subheading-4 d-block">Placement Drive Policy</label>
                          { addProcessModal.file && addProcessModal.file.file_url ?
                            <div className="r-tag r-tag--type-1" style={{width: 200}}>
                              <AttachmentLogo width={10} /><a href={addProcessModal.file.url} target="_blank" rel="noopener noreferrer">{addProcessModal.file.file_name}</a> <Button type="button" className="r-tag__delete fi flaticon-plus-1" onClick={() => {this.setState({addProcessModal: {...addProcessModal, file_id: 0, file: null}})}}></Button>
                            </div>
                            :
                            <input name="policy_file" style={{width: 200}} accept=".pdf" id={`process_policy_upload_1`} type="file" className=" mb-3" />
                          }                     
                          <FormGroup>
                            <label className="r-subheading-4">Set Registration Deadline <span id="popover-1" ><InfoIcon style={{width: 10}} /></span></label>
                            <UncontrolledPopover className="w-100 rounded zIndex--max" trigger="legacy" placement="bottom" target="popover-1">
                              <PopoverBody className="p-3">
                                <span>Define Deadline to Students to confirm their Participation in the Placement Drive</span>
                              </PopoverBody>
                            </UncontrolledPopover>
                            <SingleDatePicker value={addProcessModal.deadline || null}
                              onDateChange={(date) => {addProcessModal.deadline = moment(date).format('YYYY-MM-DD'); this.setState({addProcessModal})}}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label className="r-subheading-4">Set Registration amount of Placement Drive <InfoIcon id="popover-2" style={{width: 10}} /></label>
                            
                            <UncontrolledPopover className="w-100 rounded zIndex--max" trigger="legacy" placement="bottom" target="popover-2">
                              <PopoverBody className="p-3">
                                <span>Define Registration Amount that students have to pay to register for this Placement Drive and use the platform, the amount will be directly transferred to Reculta's bank account</span>
                              </PopoverBody>
                            </UncontrolledPopover>

                            <Input value={addProcessModal.payment || ""} onChange={(e) => {addProcessModal.payment = e.target.value; this.setState({addProcessModal})}} placeholder="Enter the amount to be pay by Students" type="number" />{' '}
                          </FormGroup>
                        </UncontrolledCollapse>
                        <div className="text-right mt-3">
                          <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
                        </div>
                      </form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
              <Row className="align-items-center my-3">
                <Col xs="auto">
                  {selectedProcess && <div className="add-count r-bg--brandB">
                    <Button onClick={() => this.setState({ fetchStudentModal: true })} className="r-btn--no-radius p-0" color="brandB">
                      <i className="fi flaticon-user" />
                      <span className="mx-2">{processStudent ? processStudent.length : 0} {processStudent && processStudent.length === 1 ? "Student" : "Students"}</span>
                    </Button>
                    <Button onClick={this.addStModal} className="r-btn--no-radius border-left p-0" color="brandB">
                      <i className="fi flaticon-plus-1  pl-2" />
                    </Button>
                  </div>
                  }
                  <Modal size="xl" zIndex="99999" isOpen={addStudentModal} toggle={() => this.setState({ addStudentModal: false })}>
                    <ModalBody>
                      <h2 className="r-subheading-1 text-center">Select Students to import them to placement drive</h2>
                      <p className="r-heading-4"><span className="r-text--green-1">{selectedCount} students </span> selected</p>
                      <FilterComponent filterStudents={this.filterStudents} />
                      <div className="text-center mt-4">
                        <Button onClick={this.mapStudenttoProcess} color="dark" className="r-btn--no-radius">Import</Button>
                      </div>
                    </ModalBody>
                  </Modal>
                  <StudentTable modal={this.state.fetchStudentModal} toggle={() => this.setState({ fetchStudentModal: false })} processStudent={processStudent} confirmDeleteStudent={this.confirmDeleteStudent} />
                </Col>
                <Col xs="auto">
                  {(processList.length !== 0) && (processStudent.length !== 0) ? <Button onClick={this.addCoModal} className="add-count" color="brandB">
                    <i className="fi flaticon-work" />
                    <span className="mx-2">Add company</span>
                    <i className="fi flaticon-plus-1 border-left pl-2" />
                  </Button> : null}

                </Col>
                {selectedProcess && <Col xs="" className="text-right">

                  {
                    ((this.props.role.hasPermission.offer) && (this.props.role.hasPermission.offer.permission_access.create === 1) && (processList.length !== 0) && (processStudent.length !== 0)) ? <Link to={`/pt/processes/${selectedProcess.process_id}/offers`}><Button className="r-text--sm r-btn r-btn--dark" color="dark">Offers</Button></Link> : null
                  }
                </Col>}
              </Row>
            </Col>

            <Modal className="modal--delete" isOpen={deleteModal} toggle={() => this.setState({ deleteModal: false })}>
              <ModalBody>
                <p>Are you sure?</p>
                <div className="text-right">
                  <Button color="dark" className="mr-2 r-btn--no-radius r-text--sm" onClick={() => this.setState({ deleteModal: false })}>Cancel</Button>
                  <Button color="dark" className="r-btn--no-radius r-text--sm" onClick={this.deleteProcess}>Delete</Button>
                </div>
              </ModalBody>
            </Modal>
            <Modal className="modal--delete" isOpen={deleteprocessCompanyModal} toggle={() => this.setState({ deleteprocessCompanyModal: false })}>
              <ModalBody>
                <p>Are you sure?</p>
                <div className="text-right">
                  <Button color="dark" className="mr-2 r-btn--no-radius r-text--sm" onClick={() => this.setState({ deleteprocessCompanyModal: false })}>Cancel</Button>
                  <Button color="dark" className="r-btn--no-radius r-text--sm" onClick={this.deleteProcessCompany}>Delete</Button>
                </div>
              </ModalBody>
            </Modal>
          </Row>
          <Row className="border-bottom">
          {
            Object.keys(tabs).map(tab => {
              return (
                <Col xs={2} key={tab}>
                <Button onClick={async() => {await this.setState({ selectedTab: tab}) ; this.handleTabSelection()}} className={`px-2 text-left r-btn--no-radius ${selectedTab === tab ? "apply__tab--selected" : ""}`}>
                <span className="d-block r-heading-4 r-text--brandB">{tabs[tab].length}</span>
                <span className="d-inline-block r-subheading-4">{tab}</span>
                </Button>
                </Col>
              )
            })
          }
          </Row>
          <Row className="py-3 justify-content-between">
            <Col xs={4}>
              <div className="text-middle">
                <InputField className="w-100 r-radius--3 pr-5" placeholder="Search for Companies/Assignee/Roles" value={companyFilters.query} onChange={(e) => this.handleFilterChange(e.target.value, 'query')} />
                <i style={{ position: 'absolute', marginLeft: '-30px' }} className="pt-2 r-text--grey-4 fi flaticon-search"></i>
              </div>
            </Col>
            <Col>
              <Row className="justify-content-end my-2" noGutters>
                {
                  this.state.classificationDetails && this.state.classificationDetails.map(category => 
                    <Col xs={"auto"} className="pr-3">
                      <div className="r-dropdown  border-0">
                      <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={filterCompany.classification_id || ""} onChange={(e) => {filterCompany.classification_id = e.target.value; this.setState({filterCompany, currentPage: 0});}}>
                        <option value="">All {category.name}</option>
                        {
                          category.classification_list.map(classification => {
                            return <option key={classification.classification_id} value={classification.classification_id}>{classification.name}</option>
                          })
                        }
                      </select>
                      <i className="fi flaticon-arrow"/>
                      </div>
                    </Col>
                  )
                }
                { !isShowOnlyAssignedCompany &&
                <Col xs={"auto"} className="pr-3">
                    <div className="r-dropdown border-0 d-inline-block">
                      <select className="r-dropdown__select r-subheading-4 r-text--grey-4" value={filterCompany.pt_member_id || ""} onChange={(e) => {filterCompany.pt_member_id = e.target.value; this.setState({filterCompany, currentPage: 0});}}>
                        <option value="">All Members</option>
                        {
                          ptList.map(pt => {
                            return <option key={pt.id} value={pt.user_id}>{getFullName(pt.userDetail)}{this.props.user.id === pt.user_id && " (me)"}</option>
                          })
                        }
                      </select>
                      <i className="fi flaticon-arrow"/>
                      
                    </div>
                  
                </Col>
                }
                <div> <img style={{width: 15}} src="/images/filter-512.png" alt="" /></div>
              </Row>
            </Col>
            <Col xs="auto">
              {window.shortlistingVerifierId && <Button onClick={this.toggleRm} className="r-text--sm ml-2" color={shortlistVerification ? "blue-3" : "blue"}>Shortlist Verification</Button>}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              {companies.length > 0 && <Row noGutters>
                <Col xs={3} className="px-2">
                  <h3 className="r-heading-6">Company Name</h3>
                </Col>
                <Col xs={9}>
                  <Row noGutters>
                    <Col xs={2} className="px-2">
                      <h3 className="r-heading-6">Assignee</h3>
                    </Col>
                    <Col xs={3} className="px-2">
                      <h3 className="r-heading-6 text-center">Status</h3>
                    </Col>
                    <Col xs={7} className="px-2">
                      <h3 className="r-heading-6 text-center">Application Status</h3>
                    </Col>
                  </Row>
                </Col>
              </Row>}
              <Row className="mb-3">
                <Col xs={12}>
                  {processList.length !== 0 ? null :
                    <Button onClick={() => this.setState({ addProcessModal: {} })} square="1" className="w-100 p-3 m-4 border add_process_company_btn" color="blue">Click to create first Placement Drive</Button>
                  }
                  {((processList.length !== 0) && (processStudent.length === 0)) ?
                    <Button onClick={this.addStModal} square="1" className="w-100 p-3 m-4 border add_process_company_btn" color="blue">Click to add student in Placement Drive</Button> : null
                  }
                </Col>
                <Col xs={3} className="pl-5">
                  {(processStudent.length > 0 && addCompanyModal) ? <Row className="align-items-center borderBottomBlack pl-2">
                    <Autosuggest
                      inputProps={{
                        className: "tableinput r-nav__search mt-4",
                        onChange : (event, { newValue, method }) => {
                           this.setState({companySearch: newValue})},
                        placeholder: 'Search companies to add in placement drive',
                        value: companySearch,
                        name: "companySearch"
                      }}
                      suggestions={companyData}
                      onSuggestionsFetchRequested={(data) => this.autoSearch(data)}
                      getSuggestionValue={(company) => {return company.company_details.company_name}}
                      renderSuggestion={(company) =>  {return(<div>{company.company_details.company_name}</div>)} }
                      onSuggestionsClearRequested={() => this.clearSuggestionList()}
                      onSuggestionSelected = {(event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method })=>
                      this.setCompany(suggestion)}
                    />

                    <i onClick={() => this.setState({ addCompanyModal: false })} className="d-inline-block fi flaticon-plus-1 rotate--45" style={{ cursor: "pointer" }} />
                  </Row> : null}
                </Col>
              </Row>
              {filteredCompanies.slice(pagination.currentPage * pagination.rowsPerPage, (pagination.currentPage * pagination.rowsPerPage) + pagination.rowsPerPage).map((company, id) => {
                var binderSortedProfileId = [];
                let profileApplications = applications.reduce((prev, current) => {
                  let temp = prev;
                  if (company.process_company_id === current.process_company_id) {
                    temp[current.process_company_profile_id] = current;
                  }
                  return temp;
                }, {})

                binderSortedProfileId = Object.keys(profileApplications).sort((a, b) => profileApplications[a].application_binder_id > profileApplications[b].application_binder_id ? -1 : 1)
                return <Company fetchProcessData={this.fetchProcessData} processList={processList} showApplication={this.getApplications} campus_id={this.props.role.instituteDetail.campus_id} process_company_id={company.process_company_id} deleteProcessCompany={this.confirmProcessCompany} shortlistVerification={shortlistVerification} ptList={ptList} toggleLoading={this.toggleLoading} handleDataChange={this.handleDataChange} selectedApplication={selectedApplication} selectedCompany={selectedCompany} toggleApplication={this.toggleApplication} toggleCompany={this.toggleCompany} key={id} {...company} profileApplications={profileApplications} getCompanies={() => this.getCompanies(selectedProcess.process_id)} role={this.props.role} binderSortedProfileId={binderSortedProfileId} processId={selectedProcess.process_id} shouldPublish={processStudent.length > 0 ? true : false} processStudent={processStudent} companies={companies} classificationDetails={this.state.classificationDetails}/>
              })}
            </Col>
          </Row>
          {filteredCompanies.length > 0 && <Row className="justify-content-end mt-3">
            <Col xs="auto">
              <PaginationComponent
                totalItems={filteredCompanies.length}
                pageSize={pagination.rowsPerPage}
                onSelect={this.onPageChange}
                activePage={pagination.currentPage + 1} />
            </Col>
          </Row>}
        </Aux>}
        <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
        <Confirmation isOpen={this.state.isConfirmDeleteStudent} toggleConfirmation={this.toggleDeleteStudentConfirmation} callback={this.deletestudent} />
      <HelpSection tab={"placementsTab"} />
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  ...state
})

export default connect(mapStateToProps, null)(Process)

import React, { Component } from 'react';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { Nav, NavItem, NavLink, TabContent, TabPane,Table } from 'reactstrap';
import classnames from 'classnames';
import { getSelectionContainerElement, getFiles, resolveValuesFromObject, setValuesToObject, generateMasterCV } from '../../../../actions';
import { Toolbar } from '../../cv-builder/toolbar';
import { STYLE_RANGES } from '../../../../constants/style-ranges';
import { CVPreview } from '../../cv-builder/cv-preview';
import Button from '../../../global/Button';
import { Auth } from '../../../../actions/Auth';
import { uploadFile } from '../../../../actions';
import Aux from '../../../global/AuxComp';
import { Loader } from '../../../global/Loader';
import Collapse from 'reactstrap/lib/Collapse';
import { TEMPLATE_CONFIG, reRenderCVPoints } from '../../../../constants/template-1';
import { Select } from '../../../global/Forms/Select';
import { Switch } from '../../../global/Forms/ToggleSwitch';
import moment from 'moment';
import { InputField } from '../../../global/Forms';
import { RTE } from '../../../global/Forms/RTE';
import { Errorbar } from '../../../global/errorbar';
import { CONSTANTS } from '../../../../constants';
export class ManageResume extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.RTEProps = {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{ 'list': 'bullet' }],
          [{ align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ 'letterspacing': ["-0.5px", "0", "0.5px"] }],
          [{ 'script': 'sub' }, { 'script': 'super' }]
        ],
      },

      formats: [
        'bold', 'italic', 'underline', 'list', 'bullet', 'align', 'letterspacing', 'script'
      ]
    }
  }

  getInitialState = () => {
    let hasPermission = this.props.role.hasPermission;
    return {
      sideNavLeft: null,
      resumes: [],
      categories: [],
      isCleanPreview: false,
      activeTab: '1',
      isLoading: false,
      config: TEMPLATE_CONFIG,
      selectedResume: null,
      addModal: false,
      deleteModal: false,
      lockModal: false,
      templateModal: false,
      editCVPointModal: false,
      showCompany:false,
      fetchError: false,
      isSideNav: false,
      editable: null,
      isToolbarOpen: false,
      isOpenOldCV:false,
      uploadedFile: null,
      //canUpload: true,
      cvName: '',
      errorbar: false,
      selectCvCompany:[],
      message: '',
      wrapperHeight: 0,
      editCVData: null,
      selectedCvApplications : [],
      allowPTEdit: hasPermission.editStudentResume && hasPermission.editStudentResume.permission_access && hasPermission.editStudentResume.permission_access.read
    }
  }
  toggleLoading = (show) => {
    this.setState({
      isLoading: show
    })
  }
  toggleToolbar = () => {
    this.setState({
      isToolbarOpen: !this.state.isToolbarOpen
    })
  }
  toggleCleanPreview = () => {
    this.setState({
      isCleanPreview: !this.state.isCleanPreview
    })
  }
  toggleSidebar = (e, value) => {
    //if(!e.target.closest('.r-sidenav__menu'))
    this.setState({
      isSideNav: value,
      editable: null
    })

  }

  printResume = async ({ cv_name, cv_id }) => {
    const { selectedResume } = this.state,
      { studentDetails } = this.props;
    let filename = cv_name || "resume"
    await this.setState({
      isLoading: true
    })
    if (selectedResume.cv_type == "UPLOADED") {
      let fileID = [];
      fileID.push(selectedResume.file_id)
      let fileResp = await getFiles(fileID)
      const link = document.createElement('a');
      link.href = fileResp[selectedResume.file_id][0].url;
      link.setAttribute('download', `${filename}.pdf`);
      link.setAttribute('target', "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove()
    }
    else {
      try {
        let response = await Auth.Student.downloadResume({ cv_id, student_id: studentDetails.user_info.student_id })
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${filename}.pdf`);
        link.setAttribute('target', "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove()
      } catch (e) { console.log(e) }
    }
    this.setState({
      isLoading: false
    })
  }

  async componentDidMount() {
    await this.fetchResumes();
    if (!this.state.fetchError)
      await this.checkMasterResume();
    var el = document.querySelector(".left-sidenav");
    if(el){
        var leftSideNavCol = el.getBoundingClientRect();
        var fixedSideNavStyle = {
          left: leftSideNavCol.left,
          width: leftSideNavCol.width
        }
        this.setState({
          sideNavLeft: fixedSideNavStyle
        })
    }
  }


  toggleAddModal = () => {
    this.setState({ uploadedFile: null })
    this.setState({
      addModal: !this.state.addModal
    })
  }
  toggleDeleteModal = () => {
    this.setState({ deleteModal: !this.state.deleteModal })
  }
  toggleLockModal = () => {
    this.setState({ lockModal: !this.state.lockModal })
  }
  
  toggleTemplateModal = () => {
    this.setState({ templateModal: !this.state.templateModal })
  }
  toggleEditCVPointModal = () => {
    this.setState({ editCVPointModal: !this.state.editCVPointModal })
  }

  checkMasterResume = async () => {
    let { resumes, config } = this.state,
      template_id = null;
    if (this.props.cvTemplates.length) {
      config = this.props.cvTemplates[0].template
      template_id = this.props.cvTemplates[0].template_id
    }
    let master = resumes.filter(cv => cv.cv_type === "MASTER"),
      method = Auth.Student.createResume,
      cvData = {
        cv_type: "MASTER",
        cv_name: "Master Resume",
        cv_category_id: 1,
        student_id: this.props.studentDetails.user_info.student_id,
        template_id
      };
    if (master.length) {
      // try {
      //   config = Object.assign({}, config, master[0].cv_template);
      // } catch (e) { console.log(e) }
      method = Auth.Student.updateResume
      cvData.cv_id = master[0].cv_id
    } else {
      method = Auth.Student.createResume
    }
    cvData.cv_template = generateMasterCV(this.props.studentCVDetails, config);
    try {
      this.toggleLoading(true)
      await method(cvData);
      await this.fetchResumes()
    } catch (e) { console.log(e) }
    await this.setState({
      selectedResume: this.state.resumes[0]
    })
    this.updateCVConfig(cvData.cv_template)
    this.toggleLoading(false)
  }

  updateCVConfig = (config) => {
    let { selectedResume, resumes } = this.state;
    selectedResume.cv_template = config;
    resumes.forEach(cv => {
      if (cv.cv_id === selectedResume.cv_id)
        cv = selectedResume
    })
    this.setState({ selectedResume, resumes })
    setTimeout(() => {
      let wrapper = document.getElementById("cvWrapper")
      this.setState({ wrapperHeight: wrapper ? wrapper.clientHeight : 0 })
    }, 0)
  }

  generateCVPoints = async (cv) => {
    let config = JSON.parse(JSON.stringify(cv.cv_template))
    config = reRenderCVPoints(this.props.studentDetails.cv_point, config);
    if (JSON.stringify(config) !== JSON.stringify(cv.cv_template)) {
      await this.setState({
        selectedResume: { ...this.state.selectedResume, cv_template: config }
      })
      this.saveResume()
    }
  }

  updateCVName = (name) => {
    let { selectedResume, resumes } = this.state;
    selectedResume.cv_name = name;
    resumes.forEach(cv => {
      if (cv.cv_id === selectedResume.cv_id)
        cv = selectedResume
    })
    this.setState({ selectedResume, resumes })
  }

  fetchResumes = async () => {
    let { studentDetails } = this.props;
    this.toggleLoading(true)
    try {
      let resumesResp = await Auth.Student.getResumes(studentDetails.user_info.student_id);
      let categoryResp = await Auth.Student.getCVCategories();
      resumesResp.data.forEach(cv => {
        cv.cv_template = JSON.parse(cv.cv_template)
      })
      await this.setState({ resumes: resumesResp.data, categories: categoryResp.data });
    } catch (e) { this.setState({ fetchError: true }) }
    this.toggleLoading(false)
  }
  fetchSelectedCvApplication = async (resume_id) => {
    this.setState({selectedCvApplications : []})
    this.toggleLoading(true)
    try {
      let resumesResp = await Auth.Student.getResumeApplication({resume_id});
      this.setState({ selectedCvApplications: resumesResp.data });
    } catch (e) { this.setState({ fetchError: true }) }
    this.toggleLoading(false)
  }

  saveResume = async (evt) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    let { selectedResume } = this.state;
    try {
      this.toggleLoading(true)
      await Auth.Student.updateResume(selectedResume);
      this.toggleLoading(false)
      await this.fetchResumes()
      let cv = this.state.resumes.filter(cv=>cv.cv_id===selectedResume.cv_id)[0]
      if(cv) this.selectResume(cv)
    } catch (e) {
        this.toggleLoading(false);
         if (e.response) {
            this.setState({ errorbar: true });
            this.setState({ message: e.response.data.error });
          }
          else {
            this.setState({ errorbar: true });
            this.setState({ message: "Sorry! something went wrong. Please try again" });
          }
    }
  }

  addCustomResume = async (e) => {
    e.preventDefault();
    let { studentDetails } = this.props;
    let { cv_id, cv_name, cv_category_id } = e.target;
    if (!cv_id.value || !cv_name.value || !cv_category_id.value) {
      this.setState({ errorbar: true, message: "Please select all fields" });
      return
    }
    let formData = {
      cv_type: "CUSTOM",
      student_id: studentDetails.user_info.student_id,
      cv_name: cv_name.value,
      cv_category_id: cv_category_id.value,
      cv_template: this.state.resumes.filter(cv => cv.cv_id == cv_id.value)[0].cv_template
    }
    try {
      this.toggleLoading(true)
      let resume = await Auth.Student.createResume(formData);
      formData.cv_id = resume.data.insertId;
      this.setState({
        selectedResume: formData
      })
      this.toggleLoading(false)
      this.toggleAddModal()
      await this.fetchResumes()
    } catch (e) { this.toggleLoading(false) }

  }

  submitUploadResume = async (e) => {
    e.preventDefault();
    let { studentDetails } = this.props;
    let { cv_name, cv_category_id } = e.target;
    if (!cv_name.value || !cv_category_id.value || !this.state.uploadedFile) {
      this.setState({ errorbar: true, message: "Please select all fields" });
      return
    }
    if(this.state.uploadedFile.size >= 2048000){
      return  this.setState({ errorbar: true, message: "File size must be under 2MB" });
    }
    this.toggleLoading(true)
    try {
      let newFile = await uploadFile(this.state.uploadedFile, "cv_upload/" + studentDetails.user_info.student_id)
      if (newFile.file_id) await Auth.Student.createResume({ file_id: newFile.file_id, cv_name: cv_name.value, student_id: studentDetails.user_info.student_id, cv_type: "UPLOADED", cv_category_id: cv_category_id.value })
      this.toggleLoading(false)
      this.toggleAddModal()
      await this.fetchResumes()
    } catch (e) { this.toggleLoading(false) }

  }

  deleteResume = async () => {
    let { selectedResume } = this.state;
    try {
      this.toggleLoading(true)
      await Auth.Student.deleteResume(selectedResume.cv_id);
      this.toggleLoading(false)
      this.toggleDeleteModal();
      await this.fetchResumes()
      this.setState({
        selectedResume: this.state.resumes[0]
      })
    } catch (e) {
      this.toggleLoading(false)
      if (e.response) {
        this.setState({ errorbar: true });
        this.setState({ message: e.response.data.error });
      }
      else {
        this.setState({ errorbar: true });
        this.setState({ message: "Sorry! something went wrong. Please try again" });
      }
    }
  }
  lockResume = async (e) => {
    e.preventDefault();
    let { selectedResume } = this.state;
    let { studentDetails } = this.props;
    let lockedName = e.target.lock_resume_name.value || selectedResume+'-Locked';
    try {
      this.toggleLoading(true)
      let s3Resp = await Auth.Student.lockResume({ cv_id : selectedResume.cv_id, student_id: studentDetails.user_info.student_id , lockResume : true})
      var s3RespKey = s3Resp.data.Key.split('/');
      let fileMangerData = {
        file_url : s3Resp.data.Key,
        file_name: s3RespKey[s3RespKey.length-1]
      }
      let newFileResp = await Auth.User.addFileToManager(fileMangerData);
      let newFile = newFileResp.data[0];
      if (newFile.file_id) await Auth.Student.createResume({ file_id: newFile.file_id, cv_name: lockedName, student_id: studentDetails.user_info.student_id, cv_type: "UPLOADED", cv_category_id: selectedResume.cv_category_id })
      this.toggleLoading(false)
      this.toggleLockModal();
      await this.fetchResumes()
      this.setState({
        selectedResume: this.state.resumes[0]
      })
    } catch (e) {
      this.toggleLoading(false)
      if (e.response) {
        this.setState({ errorbar: true });
        this.setState({ message: e.response.data.error });
      }
      else {
        this.setState({ errorbar: true });
        this.setState({ message: "Sorry! something went wrong. Please try again" });
      }
    }
  }

  editCVPoint = (id) => {
    this.cv_point_id = id;
    this.toggleEditCVPointModal();
  }

  submitCVPoint = async () => {
    let { studentDetails } = this.props;
    let editor = this.cvPointText.makeUnprivilegedEditor(this.cvPointText.getEditor())
    let formData = {
      cv_point_id: this.cv_point_id,
      student_id: studentDetails.user_info.student_id,
      header: this.cvHeader.value,
      text: editor.getHTML()
    }
    try {
      this.toggleLoading(true)
      await Auth.Student.updateCVPoint(formData);
      this.toggleLoading(false)
      await this.props.getStudentProfile();
      this.cv_point_id = null
      this.toggleEditCVPointModal()
    } catch (e) {
      console.log(e)
      this.toggleLoading(false)
    }
  }

  updateSettings = (path, value) => {
    let { config, selectedResume } = this.state
    if (selectedResume) config = selectedResume.cv_template
    config.settings = setValuesToObject(path, JSON.parse(JSON.stringify(config.settings)), value);
    this.updateCVConfig(config)
  }

  updateStyling = (propName, op, value, path) => {
    let el = getSelectionContainerElement(path);
    let { config, selectedResume } = this.state
    if (selectedResume) config = selectedResume.cv_template
    if (!el || !el.dataset.configpath)
      return;
    let styleObj = resolveValuesFromObject(el.dataset.configpath, JSON.parse(JSON.stringify(config.section_config))) || {}
    styleObj[propName] = styleObj[propName] || (STYLE_RANGES[propName] ? STYLE_RANGES[propName].default : '');
    if (value) {
      styleObj[propName] = value;
    } else if (op) {
      if ((styleObj[propName] === STYLE_RANGES[propName].min && op === -1) ||
        (styleObj[propName] === STYLE_RANGES[propName].max && op === 1))
        return
      styleObj[propName] = (parseInt(styleObj[propName]) + (1 * op)) + STYLE_RANGES[propName].unit;
    }
    config.section_config = setValuesToObject(el.dataset.configpath, JSON.parse(JSON.stringify(config.section_config)), styleObj)

    this.updateCVConfig(config)
  }

  reOrderSections = (sections) => {
    let sectionOrder = {},
      { config, selectedResume } = this.state
    if (selectedResume) config = selectedResume.cv_template
    sections.forEach((section, i) => {
      sectionOrder[String.fromCharCode(97 + i)] = section
    })
    config.section_order = sectionOrder
    this.updateCVConfig(config)
  }


  handleUploadFile = async (event) => {
    if (event.target.files[0].type !== 'application/pdf') {
      this.setState({ errorbar: true });
      this.setState({ message: "Resume should be pdf" });
    }
    else if ((event.target.files[0].size / (1024 * 1024)) <= CONSTANTS.FILE_SIZE_LIMIT) {
      let x = document.getElementById("resumeNameUpload")
      x.value = event.target.files[0].name;
      this.setState({
        uploadedFile: event.target.files[0]
      })
    }
    else {
      this.setState({ errorbar: true });
      this.setState({ message: "File size it too large" });
    }
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }



  toggle(tab) {
    const { settings, studentDetails } = this.props;
    var studentInfo = studentDetails.student_info[0] || {};
    if (this.state.activeTab !== tab) {
      this.refs.addResume.reset()
      if ((settings.STUDENT && (settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id] && settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].student_upload !== 0))) { this.refs.uploadResume && this.refs.uploadResume.reset() }
      this.setState({
        activeTab: tab,
        uploadedFile: null
      });
    }
  }

  selectResume = async (cv) => {
    await this.setState({ selectedResume: cv })
    this.updateCVConfig(cv.cv_template)
  }

  updateMasterTemplate = async(e) => {
    e.preventDefault()
    let {selectedResume} = this.state;
    selectedResume.template_id = parseInt(e.target.template_id.value)
    await this.setState({selectedResume})
    await this.saveResume()
    await this.props.getStudentProfile()
    await this.checkMasterResume()
    this.toggleTemplateModal()
  }

  saveCVItemData = () => {
    let { editCVData, config, selectedResume } = this.state;
    let editor = this.itemRTE.makeUnprivilegedEditor(this.itemRTE.getEditor())
    if (selectedResume) config = selectedResume.cv_template
    config.section_config = setValuesToObject(editCVData, JSON.parse(JSON.stringify(config.section_config)), editor.getHTML())
    this.updateCVConfig(config)
    this.saveResume()
    this.setState({ editCVData: null })
  }

  renderCV = (cv) =>{
    const { studentCVDetails, isPT, settings, allTemplates, cvTemplates } = this.props;
    const { isLoading, resumes, categories, selectedResume,templateModal, allowPTEdit, addModal, editCVPointModal, deleteModal,lockModal, isSideNav, editable, sideNavLeft, isCleanPreview, wrapperHeight, uploadedFile, editCVData,showCompany,selectCvCompany,isOpenOldCV } = this.state;
      let isActive = selectedResume && (selectedResume.cv_id === cv.cv_id);
      var studentInfo = this.props.studentDetails.student_info[0] || {};

    return(
                <li onClick={() => this.selectResume(cv)} key={cv.cv_id} className={isActive ? "sidenav-active" : ""}>
                  {editable !== cv.cv_id ?
                    <span className="d-block mb-3">
                      {cv.cv_name}{cv.cv_type !== "MASTER" && <Button onClick={() => this.setState({ editable: cv.cv_id })} className="fi flaticon-edit-2" />}
                      {cv.cv_type !== "MASTER" && <Button onClick={() => this.setState({ showCompany : showCompany ? false : cv.cv_id })} style={{position:"absolute",right:"0%"}} className="fi flaticon-info" />}
                    </span> :
                    <form className="mb-3" onSubmit={this.saveResume}>
                      <InputField type="text" name="cv_name" value={cv.cv_name} onChange={(e) => this.updateCVName(e.target.value)} />
                    </form>}
                    <div className="row mx-0">
                          <Button className="p-0 pr-3 fi flaticon-file" onClick={() => this.printResume(cv)} />
                          {(cv.cv_type !== "MASTER") && <Button onClick={this.toggleDeleteModal} className="p-0 fi pr-3 flaticon-bin" /> }
                          {(cv.cv_type === "CUSTOM" && this.props.settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id] && this.props.settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].cv_lock) ? <Button onClick={this.toggleLockModal} className="p-0 fi flaticon-security" /> : "" }
                          {(cv.cv_type === "MASTER" && allTemplates.length>1) && <Button color="link" className="ml-2" onClick={this.toggleTemplateModal}><i className="mr-1 fi flaticon-edit-2"/></Button>}
                          <div className="ml-3">
                              { <em className="d-block r-text--sm">{moment(cv.created_on).format("Do MMMM YYYY")}</em> }
                              { <em className="d-block r-text--sm">{categories.filter(cat => {return (cat.cv_category_id===cv.cv_category_id)})[0]["cv_category"]}</em> }
                          </div>
                    </div>
                  {cv.cv_type === "MASTER" && <i className="resumes__star fi flaticon-star" />}
                  {((!isPT || (!!isPT && !!allowPTEdit)) && isActive && selectedResume && (cv.cv_type !== "MASTER")) &&
                    <Button onClick={this.saveResume} title="Save Resume" type="button" style={{bottom: 10, right : 10, position: "absolute", zIndex: 99}} className="r-bg--light r-text--blue-3">Save</Button>}
                  {cv.cv_type === "UPLOADED" && <i className="pr-0 fi flaticon-document r-text--brandC float-right" title="Uploaded Resume"></i>}

                </li>
        )
  }

  render = () => {
    const { studentCVDetails, isPT, settings, allTemplates, cvTemplates } = this.props;
    const { isLoading, resumes, categories, selectedResume,templateModal, allowPTEdit, addModal, editCVPointModal, deleteModal,lockModal, isSideNav, editable, sideNavLeft, isCleanPreview, wrapperHeight, uploadedFile, editCVData,showCompany,selectCvCompany,isOpenOldCV, selectedCvApplications } = this.state;
    let sections = [],
      config = this.state.config,
      showToolbar = true, showUnverified = true, enableDrag = true, isSinglePageCV = false;
    if (selectedResume && selectedResume.cv_type !== "UPLOADED") {
      config = selectedResume.cv_template;
      sections = Object.keys(config.section_order).sort().map(order => {
        return config.section_order[order]
      })
    }
    var studentInfo = this.props.studentDetails.student_info[0] || {};
    if (settings.STUDENT && settings.STUDENT.STUDENT_CV_SETTING && settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id]) {
      if (settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].cv_toolbar === 0)
        showToolbar = false
      if (settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].show_unverified === 0)
        showUnverified = false
      if (settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].enable_section_drag === 0)
        enableDrag = false
      if (selectedResume && selectedResume.cv_type !== "MASTER" && settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].is_single_page_cv === 1)
        isSinglePageCV = true
    }
    let cvPoint = studentCVDetails.cv_point[this.cv_point_id] || {},
    cvItemData = editCVData ? resolveValuesFromObject(editCVData, JSON.parse(JSON.stringify(config.section_config))) : "";
    let resumeCategory = {'Master CV':[],'Latest CV':[],'Older CV':[]};
    let sortCV = resumes.sort((a, b) => {var dateA = new Date(a.created_on), dateB = new Date(b.created_on);return dateA - dateB;});
    sortCV.forEach((cvSorted,i) =>{
        if(cvSorted.cv_type === "MASTER"){
            resumeCategory['Master CV'].push(sortCV[i]);
        }
        else if(i===(sortCV.length-1)){
            resumeCategory['Latest CV'].push(sortCV[i]);
        }
        else{
            resumeCategory['Older CV'].push(sortCV[i]);
        }
    })
    return (
      <Aux>
        <Loader show={isLoading} />
        <Modal zIndex={99999} isOpen={addModal} toggle={this.toggleAddModal}>
          <ModalBody>                  <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}
                style={{ cursor: "pointer" }}
              >
                Create Resume
                      </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggle('2'); }}
                style={{ cursor: "pointer" }}
              >
                Upload Resume
                      </NavLink>
            </NavItem>
          </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <form ref="addResume" onSubmit={this.addCustomResume}>
                  <Row form className="mt-3">
                    <Col>
                      <label className="r-heading-6">Select base template</label>
                      <Select name="cv_id" id="baseTemplate">
                        <option value="">Select</option>
                        {resumes.map(cv => {
                          return cv.cv_type === "UPLOADED" ? "" : <option key={cv.cv_id} value={cv.cv_id}>{cv.cv_name}</option>
                        })}
                      </Select>
                    </Col>
                  </Row>
                  <Row form className="mt-3">
                    <Col>
                      <label className="r-heading-6">Select Category</label>
                      <Select name="cv_category_id" id="cvCategory">
                        <option value="">Select</option>
                        {categories.map(ct => {
                          return <option key={ct.cv_category_id} value={ct.cv_category_id}>{ct.cv_category}</option>
                        })}
                      </Select>
                    </Col>
                  </Row>
                  <Row form className="mt-3">
                    <Col>
                      <label className="r-heading-6">Name of the resume</label>
                      <InputField name="cv_name" placeholder="Name" className="w-100" type="text" id="resumeName" />
                    </Col>
                  </Row>
                  <Row form className="mt-3 justify-content-end r-text--sm">
                    <Col xs="auto">
                      <Button onClick={this.toggleAddModal} className="r-btn--no-radius" type="button">Cancel</Button>
                      <Button className="r-btn--no-radius" color="dark">Save</Button>
                    </Col>
                  </Row>
                </form>
              </TabPane>
              <TabPane tabId="2">
                {(settings.STUDENT && (settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id] && settings.STUDENT.STUDENT_CV_SETTING[studentInfo.batch_id].student_upload === 0)) ? <span>You can't upload resume. Please contact to your placement-team</span> :
                  <form ref="uploadResume" onSubmit={this.submitUploadResume}>
                    <Row form className="mt-3">
                      <Col>
                        <label className="r-heading-6">Name of the resume</label>
                        <InputField name="cv_name" placeholder="Name" className="w-100" type="text" id="resumeNameUpload" />
                      </Col>
                    </Row>
                    <Row form className="mt-3">
                      <Col>
                        <label className="r-heading-6">Select Category</label>
                        <Select name="cv_category_id" id="cvCategoryUpload">
                          <option value="">Select</option>
                          {categories.map(ct => {
                            return <option key={ct.cv_category_id} value={ct.cv_category_id}>{ct.cv_category}</option>
                          })}
                        </Select>
                      </Col>
                    </Row>
                    <Row form className="mt-3 justify-content-end r-text--sm">
                      <Col xs="auto">
                        <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                          <Row className="border align-items-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                            {(uploadedFile !== null) ? uploadedFile.name : "Upload Resume"}
                            <i className=" r-text--xl fi flaticon-upload r-text--grey-4 ml-3" style={{ fontSize: "16px" }} />
                          </Row>
                          <input id="file-input" accept="application/pdf" onChange={(e) => this.handleUploadFile(e)} type="file" style={{ display: "none" }} />
                          {this.state.cvName}
                        </label>
                        <Button onClick={this.toggleAddModal} className="r-btn--no-radius" type="button">Cancel</Button>
                        <Button className="r-btn--no-radius" color="dark">Save</Button>
                      </Col>
                    </Row>
                  </form>}
              </TabPane>
            </TabContent>
          </ModalBody>
        </Modal>
        <Modal className="modal--delete" isOpen={deleteModal} toggle={this.toggleDeleteModal}>
          <ModalBody>
            <p>Are you sure?</p>
            <div className="text-right">
              <Button onClick={this.toggleDeleteModal}>Cancel</Button>
              <Button className="r-btn--no-radius" color="dark" onClick={this.deleteResume}>Delete</Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal className="modal--centered" isOpen={lockModal} toggle={this.toggleLockModal}>
          <ModalBody>
            <form onSubmit={this.lockResume}>
            <p>Enter name for locked resume</p>
            <InputField className="w-100" name="lock_resume_name" defaultValue={selectedResume?selectedResume.cv_name+" - Locked" : ""} />
            <div className="text-right mt-3">
              <Button type="button" onClick={this.toggleDeleteModal}>Cancel</Button>
              <Button className="r-btn--no-radius" color="dark">Lock</Button>
            </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal className="modal--centered" isOpen={templateModal} toggle={this.toggleTemplateModal}>
          <ModalBody>
            <p>Select Master CV Template</p>
            <form onSubmit={this.updateMasterTemplate}>
            <Select name="template_id" required defaultValue={cvTemplates[0]?cvTemplates[0].template_id : ""}>
              <option value="">Select Template</option>
              {allTemplates.map(temp=><option key={temp.template_id} value={temp.template_id}>{temp.template_name}</option>)}
            </Select>
            <div className="text-right mt-3">
              <Button type="button" onClick={this.toggleTemplateModal}>Cancel</Button>
              <Button className="r-btn--no-radius" color="dark">Save</Button>
            </div>
            </form>
          </ModalBody>
        </Modal>
        <Modal className="modal--centered" isOpen={editCVPointModal} toggle={this.toggleEditCVPointModal}>
          <ModalBody>
            <div className="cv-point">
              <InputField inputRef={(ref => this.cvHeader = ref)} defaultValue={cvPoint.header} name="header" className="rounded-top w-100 cv_header_input" type="text" />
              <RTE inputRef={(ref) => this.cvPointText = ref} hideToolbar={true} defaultValue={cvPoint.text} placeholder="Type CV Point" {...this.RTEProps} />
            </div>
            <div className="text-right">
              <Button onClick={this.toggleEditCVPointModal}>Cancel</Button>
              <Button className="r-btn--no-radius" color="dark" onClick={this.submitCVPoint}>Save</Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal className="modal--centered" isOpen={editCVData !== null} toggle={() => this.setState({ editCVData: null })}>
          <ModalBody>
            <div>
              <RTE inputRef={(ref) => this.itemRTE = ref} defaultValue={cvItemData} placeholder="" />
            </div>
            <div className="text-right">
              <Button onClick={() => this.setState({ editCVData: null })}>Cancel</Button>
              <Button className="r-btn--no-radius" color="dark" onClick={this.saveCVItemData}>Save</Button>
            </div>
          </ModalBody>
        </Modal>
        <Container className="cv-builder">
          {selectedResume && <Row>
            <Col xs={3} className="px-0 left-sidenav">
              <div className="col-12 cv-builder__toolbar r-sidenav-fixed pb-0" style={sideNavLeft} >
                <Row className="border-bottom align-items-center r-text--blue-3 py-2">
                  <Col>

                    {(!isPT || (!!isPT && !!allowPTEdit)) && <div className="float-left">
                      <Button onClick={this.toggleAddModal} title="Add Resume" type="button" className="r-bg--blue-3 r-text--light" > <i className="fi flaticon-plus-1" /> New Resume</Button>
                    </div>}

                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <h1 className="r-subheading-4 r-text--grey-4 my-3">Clean Preview <div className="float-right" onClick={this.toggleCleanPreview}><Switch isOn={isCleanPreview} toggleColor={'green'} /></div></h1>
                  </Col>
                </Row>
                <Row >
                  <Col className="r-sidenav__body px-0" style={this.state.isToolbarOpen ? { height: 60, overflowY: 'scroll' } : {}}>
                    <ul className="r-sidenav__menu resumes">
                      {
                        Object.keys(resumeCategory).map(cvType=>{
                            return(
                                <div>
                                    <div key={cvType} className="pl-2 border-bottom">
                                        {cvType}
                                        {(cvType==='Older CV')&&<div className="float-right">
                                          <Button type="button" onClick={()=>{this.setState({isOpenOldCV:!isOpenOldCV})}} style={isOpenOldCV ? { transform: 'rotate(-90deg)' } : { transform: 'rotate(90deg)' }} ><i className="fi flaticon-arrow"></i></Button>
                                        </div>}
                                    </div>
                                    {(cvType!=='Older CV')&&resumeCategory[cvType].map(cv => {
                                              return (
                                                this.renderCV(cv)
                                              )
                                            })
                                    }
                                    {(cvType==='Older CV')&&resumeCategory[cvType].map(cv => {
                                              return (
                                                 <Collapse key={cv.cv_id} className="w-100" isOpen={isOpenOldCV}>
                                                    {this.renderCV(cv)}
                                                </Collapse>
                                              )
                                            })
                                    }
                                </div>
                                )
                        })
                      }
                    </ul>
                  </Col>
                </Row>
                {((!isPT || (!!isPT && !!allowPTEdit)) && showToolbar) && <Aux><Row className="border-bottom align-items-center r-text--blue-3 py-2" style={{ transition: 'all 1s', cursor: 'pointer' }}>
                  <Col onClick={this.toggleToolbar} className="pr-0" >
                    <h1 className="r-heading-4 float-left pt-2">Edit Resume</h1>
                    <div className="float-right">
                      <Button type="button" style={this.state.isToolbarOpen ? { transform: 'rotate(-90deg)' } : { transform: 'rotate(90deg)' }} ><i className="fi flaticon-arrow"></i></Button>
                    </div>
                  </Col>
                </Row>
                  <Row>
                    <Collapse className="w-100" isOpen={this.state.isToolbarOpen}>
                      <Col>
                        <Toolbar updateSettings={this.updateSettings} config={config} updateStyling={this.updateStyling} sections={sections} />
                      </Col>
                    </Collapse>
                  </Row></Aux>}
              </div>
            </Col>
            <Col xs={9} >
              <Row className="justify-content-md-center">

                {(selectedResume && selectedResume.cv_type !== "UPLOADED") ?
                  <Col id="cvWrapper" xs={12} className={"py-0 "+(isSinglePageCV ? "single_page_cv ":"") + (selectedResume.cv_type === "MASTER" ? "cv__master" : "")}>
                    <CVPreview cv_type={selectedResume.cv_type} editCVData={(path)=>this.setState({editCVData: path})} enableDrag={enableDrag} showUnverified={showUnverified} preview={isCleanPreview} editCVPoint={this.editCVPoint} updateCVConfig={this.updateCVConfig} reOrderSections={this.reOrderSections} sections={sections} data={studentCVDetails} config={config} />
                    {(wrapperHeight >= (CONSTANTS.A4_HEIGHT - 50)) && <div className="cv-builder__limit">A4 Page ends here</div>}
                  </Col> : <span>No Preview available</span>
                }
              </Row>
            </Col>
          </Row>
          }
          <Errorbar showBar={this.state.errorbar} onClose={this.closebar} message={this.state.message} />
          {showCompany && (<Modal onOpened={() => this.fetchSelectedCvApplication(showCompany)} zIndex="99999" isOpen={!!showCompany} toggle={() => this.setState({ showCompany: false })}>
                                <ModalBody>
                                    <Table>
                                      <thead>
                                        <tr className="tableheader">
                                          <th>Company Name</th>
                                          <th>Role </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {(selectedCvApplications.length>0) && selectedCvApplications.map((company,i)=>
                                          {
                                            return(
                                              <tr
                                                className="borderBottomlightblack"
                                                key={i}>
                                                <td className="r-subheading-3">{company.company_name}</td>
                                                 <td>{company.role_name}</td>
                                                </tr>
                                            )
                                          })
                    
                                          }
                                          {(selectedCvApplications.length===0 && !isLoading)&&
                                            <tr className="borderBottomlightblack" key="0"><td><span className="r-text--red-1">*No Companies Found</span></td></tr>}
                                      </tbody>
                                    </Table>
                                </ModalBody>
                              </Modal>)}
        </Container>
      </Aux>
    )
  }
}

import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Table from 'reactstrap/lib/Table';
import Button from '../../../global/Button';
import Aux from '../../../global/AuxComp';
import { Switch } from '../../../global/Forms/ToggleSwitch';
import { InputField } from '../../../global/Forms';
import { Select } from '../../../global/Forms/Select';
//import { RTE } from '../../../global/Forms/RTE';
import { getFullName, uploadFile, getErrorMessage } from '../../../../actions';
import { Auth } from '../../../../actions/Auth';
import { ApplicationModal } from './application-modal';
import share from '../../../../assets/share.svg'
import { UnblockStudents } from './unblock-student';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import moment from 'moment';
import { Loader } from '../../../global/Loader';
import { Errorbar } from '../../../global/errorbar';
import store from '../../../../store';
import { CONSTANTS } from '../../../../constants';
import { SingleDatePicker } from "../../../global/Forms/DatePicker";
import PaginationComponent from '../../../global/PaginationComponent.js';
import DownloadCv from './download'
import ShareApplicant from './share-applicant'
import RoundShortlistOverview from './round_shortlist_overview';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { setError, setLoading } from '../../../../actions/action-creators';
import { AddOffer } from './AddOffer';
import {ENDPOINTS} from '../../../../constants/endpoints';
import AddRecruiter from '../companies/add_recuiter';
import MultipleStudentApply from './multiple-student-apply';
import ShortlistQuestions from './shortlist_questions';
import Link from "react-router-dom/Link";


export class ProfileApplicationDetail extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }
  getInitialState = () => {
    return {
      applicationModal: false,
      error: {
        show: false,
        message: ""
      },
      currentPage: 0,
      rowsPerPage: 10,
      selected: {},
      selectAll: 0,
      isLoading: false,
      postModal: false,
      shortlistModal: false,
      addMilestoneModal: false,
      addStudentModal: false,
      addOffersModal: false,
      closeApplicationModal: false,
      feedbackModal: false,
      shareModal: false,
      shareRoundId: null,
      roundId: null,
      deleteRoundId: null,
      deleteRoundModal: false,
      isOpenAddRecuriter : false,
      is_hotlisting: '0',
      datepickerValue: null,
      shortListCount: 0,
      query: '',
      isShareApplicant: false,
      uploadedFile: null,
      uploadedEmailFile: null,
      activeTab: '1',
      offerDetails : [],
      feedbackList: [{
        name: "",
        email: ""
      }],
      success: null,
      generatePost : true,
      isOpenAppliedOption: false,
      isOpenRoundQuestion: false
    }
  }

  toggleAddRecuriter =() => {
    console.log(this.state.isOpenAddRecuriter)
    this.setState({isOpenAddRecuriter : !this.state.isOpenAddRecuriter});
  }

  getStudentApplicationRound = (id) => {
    this.setState({ roundId: id, shortlistModal: true, uploadedFile: null, activeTab: '1' })
    let { currentPage, rowsPerPage, shortListCount, selectAll } = this.state;
    let { role } = store.getState();
    let newSelected = {};
    let roundData = this.getRoundData(this.props.application);
    let roundDataIndex = Object.keys(roundData).indexOf("R-" + id) - 1;
    if (roundDataIndex > -1) {
      roundData[Object.keys(roundData)[roundDataIndex]].forEach(studentApplication => {
        let { student } = studentApplication;
        let studentShortlist = studentApplication.shortlisting_data.filter(data => (data.application_round_id === id && data.type === "S" ))[0]
        if (studentShortlist && studentShortlist.shortlist_status === 1) {
          newSelected[studentShortlist.student_application_id] = true;
          shortListCount += 1;
        }
        else {
          newSelected[studentApplication.student_application_id] = false;
        }
      })
      this.setState({ selected: newSelected, shortListCount });
    }
  }

  closeError = () => {
    let { error } = this.state;
    error.show = false;
    this.setState({ error })
  }

  toggleState = (state) => {
    if (state === "shortlistModal") {
      this.setState({ selectAll: 0, shortListCount: 0 })
    }
    this.setState({
      [state]: !this.state[state]
    })
  }

  handleStateChange = (e) => {
    let state = this.state[e.target.dataset.state];
    if (state) {
      state[e.target.name] = e.target.value;
      this.setState({
        [state]: state
      })
    }
  }

  getRoundData = application => {
    let { role } = store.getState(),
      { shortlistVerification } = this.props;
    let roundData = {
      "applied": [],
      "R-0": []
    }
    if (application.rounds) {
      application.rounds.forEach(round => {
        roundData["R-" + round.application_round_id] = []
      })
    }
    if (application.applicants) {
      application.applicants.forEach(a => {
        if (a.student_application_status === 2) {
          roundData.applied.push(a)
          a.shortlisting_data.forEach(shortlist => {
            if (shortlist.type === "S" && shortlist.shortlist_status === 1) {
              roundData["R-" + shortlist.application_round_id].push(a)
            }
          })
        }
      })
    }
    return roundData
  }

  checkPublish = (status) => {
    if ((this.props.shouldPublish) && (status === 0)) {
      this.toggleState("addStudentModal");
    }
    else if (!this.props.shouldPublish) {
      let { error } = this.state;
      error.show = true;
      error.message = "Please add student to the process";
      this.setState({ error })
    }
  }

  publishApplication = (stList) => {
    let application_id = this.props.application.application_id
    let body = {
      "application_id": application_id,
      "student_id": stList
    }
    Auth.Processes.publishApplication(body).then(async (resp) => {
      let applicantsResp = await Auth.Processes.getApplicants(application_id)
      this.props.handleDataChange({ application_id, publish_status: 1, ...applicantsResp.data })
      this.toggleState("addStudentModal");
    })
  }

  handleShortistSave = async () => {
    let shortlistFormData = {
      application_id: this.props.application.application_id,
      application_round_id: this.state.roundId,
      user_type: "PT",
      type: "S",
      shortlisting_data: []
    };
    if (this.props.shortlistVerification) shortlistFormData.role_id = window.shortlistingVerifierId
    let hostlistFormData = { ...shortlistFormData, type: "H", shortlisting_data: [] }
    let shortlistStatus = document.getElementsByName(`shortlist_status_${shortlistFormData.application_id}`);
    let hotlistStatus = document.getElementsByName(`hotlist_status_${hostlistFormData.application_id}`);
    hotlistStatus.forEach(s => {
      hostlistFormData.shortlisting_data.push({
        student_application_id: s.value,
        shortlist_status: s.checked ? 1 : 2
      })
    })
    // shortlistStatus.forEach(s => {
    //   let studentHotlist = hostlistFormData.shortlisting_data.filter(st => st.student_application_id === s.value)[0]
    //   shortlistFormData.shortlisting_data.push({
    //     student_application_id: s.value,
    //     shortlist_status: ((studentHotlist && studentHotlist.shortlist_status === 1) || s.checked) ? 1 : 2
    //   })
    // })

    Object.keys(this.state.selected).forEach(st => {
      shortlistFormData.shortlisting_data.push({
        student_application_id: parseInt(st),
        shortlist_status: (this.state.selected[st] === true) ? 1 : 2
      })
    })
    this.toggleState("shortlistModal");
    await Auth.Processes.updateShortlist(shortlistFormData)
    if (hostlistFormData.shortlisting_data.length > 0) {
      await Auth.Processes.updateShortlist(hostlistFormData)
    }
    Auth.Processes.getApplicants(shortlistFormData.application_id).then(resp => {
      this.props.handleDataChange({ application_id: this.props.application.application_id, ...resp.data })
    })
  }

  addMilestone = async (e) => {
    this.setState({ isLoading: true })
    e.preventDefault()
    let { round_name, round_type } = e.target,
      { application_id } = this.props.application;
    let formData = {
      application_id,
      round_name: round_name.value,
      round_type: round_type.value,
      is_hotlisting: this.state.is_hotlisting,
      hotlisting_deadline: this.state.datepickerValue ? moment(this.state.datepickerValue).utc(false).format("YYYY-MM-DD") : undefined
    }
    try {
      await Auth.Processes.addMilestone(formData);
    } catch (e) { console.log(e) }
    this.toggleState("addMilestoneModal");
    this.setState({ datepickerValue: null, is_hotlisting: '0' })
    let resp = await Auth.Processes.getApplicants(application_id)
    this.props.handleDataChange({ application_id, ...resp.data })
    this.setState({ isLoading: false })

  }

  onCloseApplication = async (e) => {
    e.preventDefault()
    this.setState({ isLoading: true })
    let { application_id } = this.props.application
    let body = {
      application_id,
      generatePost : e.target.generatePost.checked
    }
    try{
      await Auth.Processes.closeApplication(body);
      this.toggleState("closeApplicationModal");
      this.props.handleDataChange({ application_id, is_recruitment_closed: 1})
    }
    catch (e) {
      console.log(e)
      store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
    }
    this.setState({ isLoading: false })
  }

  deleteMilestone = async (e) => {
    e.preventDefault()
    let { application_id } = this.props.application,
      { deleteRoundId, error } = this.state;
    let formData = {
      application_round_id: deleteRoundId
    }
    try {
      await Auth.Processes.deleteMilestone(formData);
      try {
        let application_id = this.props.application.application_id;
        let applicantsResp = await Auth.Processes.getApplicants(application_id)
        this.props.handleDataChange({ application_id, ...applicantsResp.data })
        this.setState({ isLoading: false, shareModal: false })
      } catch (e) {
        this.setState({ isLoading: false })
        error.show = true;
        if (e.response && e.response.data.error)
          error.message = e.response.data.error.toString()
        else
          error.message = "Something went wrong, Please try again later";
        this.setState({ error })
      }
    } catch (e) { console.log(e) }
    let rounds = this.props.application.rounds.filter(r => r.application_round_id !== deleteRoundId)
    this.toggleState("deleteRoundModal");
    this.props.handleDataChange({ application_id, rounds })
  }

  shareData = (roundId) => {
    this.setState({
      shareModal: true,
      shareRoundId: roundId
    })
  }

  handleUploadFile = async (event) => {
    this.setState({
      uploadedFile: event.target.files[0]
    })
  } 
  
  handleUploadEmailFile = async (event) => {
    this.setState({
      uploadedEmailFile: event.target.files[0]
    })
  }

  uploadShortlist = async () => {
    let { roundId, error, uploadedFile } = this.state,
      { application_id, rounds } = this.props.application;
    let shortlistFormData = {
      process_id: this.props.processId,
      application_id,
      application_round_id: roundId,
      user_type: "PT",
      type: "S"
    };
    if (uploadedFile !== null) {
      store.dispatch(setLoading(true))
      try {
        let file = await uploadFile(uploadedFile, `application/${application_id}/shortlist`)
        if (file.file_url) {
          shortlistFormData.file_url = file.file_url
          await Auth.Processes.updateShortlistExcel(shortlistFormData)
          let applicantsResp = await Auth.Processes.getApplicants(application_id)
          this.props.handleDataChange({ application_id, ...applicantsResp.data })
          this.setState({ activeTab: '1', shortlistModal: false })
        } else {
          throw new Error("Error uploading file. Please try again.")
        }

      } catch (e) {
        console.log(e)
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      }
      store.dispatch(setLoading(false))
    }

  }

  uploadShortlistEmail = async () => {
    let { roundId, uploadedEmailFile } = this.state,
      { application_id, rounds } = this.props.application;
    let shortlistFormData = {
      process_id: this.props.processId,
      application_id,
      company_id: this.props.company_id,
      application_round_id: roundId,
      user_type: "PT",
      type: "S"
    };
    if (uploadedEmailFile !== null) {
      store.dispatch(setLoading(true))
      try {
        let file = await uploadFile(uploadedEmailFile, `application/${application_id}/shortlist`)
        if (file.file_url) {
          shortlistFormData.file_url = file.file_url
          await Auth.Processes.uploadShortlistEmail(shortlistFormData)
          this.setState({ activeTab: '1', shortlistModal: false })
        } else {
          throw new Error("Error uploading file. Please try again.")
        }
      } catch (e) {
        console.log(e)
        store.dispatch(setError({ showBar: true, message: getErrorMessage(e) }))
      }
      store.dispatch(setLoading(false))
    }
  }



  submitShortlist = async () => {
    let { shareRoundId, error, generatePost } = this.state,
      { application_id, rounds } = this.props.application;
    let formData = {
      application_id,
      type: "S",
      application_round_id: shareRoundId,
      generatePost
    }
    if (shareRoundId !== null) {
      this.setState({ isLoading: true })
      try {
        await Auth.Processes.releaseShortlist(formData)
        if (shareRoundId === 0)
          this.props.handleDataChange({ application_id, shortlist_release_status: 2 })
        else {
          rounds.forEach(r => {
            if (r.application_round_id == shareRoundId)
              r.shortlist_release_status = 2
          })
          this.props.handleDataChange({ application_id, rounds })
        }
        this.getRoundData(this.props.application);
        try {
          let application_id = this.props.application.application_id;
          let applicantsResp = await Auth.Processes.getApplicants(application_id)
          this.props.handleDataChange({ application_id, ...applicantsResp.data })
          this.setState({ isLoading: false, shareModal: false })
        } catch (e) {
          this.setState({ isLoading: false })
          error.show = true;
          if (e.response && e.response.data.error)
            error.message = e.response.data.error.toString()
          else
            error.message = "Something went wrong, Please try again later";
          this.setState({ error })
        }
      } catch (e) {
        this.setState({ isLoading: false })
        error.show = true;
        if (e.response && e.response.data.error)
          error.message = e.response.data.error.toString()
        else
          error.message = "Something went wrong, Please try again later";
        this.setState({ error })
      }
    }
  }

  toggleShareApplicant = () => {
    let { isShareApplicant } = this.state
    this.setState({ isShareApplicant: !isShareApplicant })
  }



  handleSelected = (selectedPage) => {
    this.setState({ currentPage: selectedPage - 1 });
  }

  onSearch = (e) => {
    let { query } = this.state
    if (e.target.value !== "") {
      this.setState({ currentPage: 0 });
    }
    query = e.target.value
    this.setState({ query })
  }


  selectRow(st) {
    let { shortListCount } = this.state;
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[st] = !this.state.selected[st];
    if (newSelected[st] === true) { shortListCount += 1 }
    if (newSelected[st] === false) { shortListCount -= 1 }
    this.setState({
      selected: newSelected,
      selectAll: 2,
      shortListCount
    });
  }

  handledDownloadSendMail = async () => {
    let {application, profile, company_name} = this.props
    let {selected, roundId} = this.state
    var selectedStudentId = []
    let roundData = this.getRoundData(this.props.application);
    let roundDataIndex = Object.keys(roundData).indexOf("R-" + roundId) - 1;
    roundData[Object.keys(roundData)[roundDataIndex]].forEach(x => {
      if(selected[x.student_application_id]) selectedStudentId.push(x.student_id)
    });
    let formData = {
      "scope":'application',
      "application_id": application.application_id,
      role_name: profile.role_name,
      company_name,
      student_id: selectedStudentId
    }
    try {
      store.dispatch(setLoading(true))
      let downloadResp = await Auth.Download.cvDownload(formData);
      await this.setState({ success: downloadResp.data.message });
      store.dispatch(setLoading(false))
    } catch (e) {
      console.log(e)
      this.setState({ errorMessage: 'Sorry something went wrong. Please try again' });
      this.setState({ errorbar: true });
    }
  }
  handledDownloadCSV = async() => {
    let {profile, company_name} = this.props
    let {selected, roundId} = this.state
    var selectedStudentId = []
    let roundData = this.getRoundData(this.props.application);
    let roundDataIndex = Object.keys(roundData).indexOf("R-" + roundId) - 1;
    roundData[Object.keys(roundData)[roundDataIndex]].forEach(x => {
      if(selected[x.student_application_id]) selectedStudentId.push(x.student_id)
    });
    let formData = {
      student_id: selectedStudentId
    }
    try{
          let csvResp = await Auth.Download.cvCSVDownload(formData)
          const url = window.URL.createObjectURL(new Blob([csvResp.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = company_name+'_'+profile.role_name+'_shortlisting.csv';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        catch(e){
          if(e.response && e.response.error){
            let message = e.response.data.error
            this.setState({ errorbar: true, message })
          }
          else{
            console.log(e);
          }
        }
    this.setState({ downloadCSVModal: !this.state.downloadCSVModal });
  }
  toggleSelectAll(type) {
    let { currentPage, rowsPerPage, roundId, shortListCount, selectAll } = this.state;
    let newSelected = {};
    shortListCount = 0;
    let roundData = this.getRoundData(this.props.application);
    let roundDataIndex = Object.keys(roundData).indexOf("R-" + roundId) - 1;
    if(type === "ALL"){
      if (selectAll === 0) {
        roundData[Object.keys(roundData)[roundDataIndex]].forEach(x => {
          newSelected[x.student_application_id] = true;
          shortListCount += 1;
        });
      }
      else {
        roundData[Object.keys(roundData)[roundDataIndex]].forEach(x => {
          newSelected[x.student_application_id] = false;
        });
      }
    }
    if(type === "PLACED"){
      roundData[Object.keys(roundData)[roundDataIndex]].forEach(x => {
        if(x.status === "Placed"){
        newSelected[x.student_application_id] = true;
        shortListCount += 1;
        }
        else newSelected[x.student_application_id] = false;
      });
    }if(type === "UNPLACED"){
      roundData[Object.keys(roundData)[roundDataIndex]].forEach(x => {
        if(x.status === "Unplaced"){
        newSelected[x.student_application_id] = true;
        shortListCount += 1;
        }
        else newSelected[x.student_application_id] = false;
      });
    }
    
    this.setState({
      selected: newSelected, shortListCount,
      selectAll: selectAll === 0 ? 1 : 0
    });

  }

  handleOfferModal = () => {
    this.setState({ addOffersModal: false });
  }
  handleCloseApplicationModal = () => {
    this.setState({ closeApplicationModal: false });
  }

  addEmailsToFeedback = () => {
    let {feedbackList} = this.state;
    feedbackList.push({name: "", email: ""})
    this.setState({ feedbackList })
  }

  removeEmailFromFeedback = (i) => {
    let {feedbackList} = this.state;
    feedbackList.splice(i,1)
    this.setState({ feedbackList })
  }

  sendFeedback = async(e) => {
    e.preventDefault()
    let {feedbackList} = this.state,
    {application, processId} = this.props;
    store.dispatch(setLoading(true))
    await Promise.all(feedbackList.map(async(contact)=>{
      let data = {...contact}
      data.application_id = application.application_id
      data.process_id = processId;
      try {
        await Auth.Feedback.request(data)
        return true
      } catch(e) {
        console.log(e)
        return false
      }
    }))
    store.dispatch(setLoading(false))
    this.setState({success: "Feedback request has been sent."})
  }

  handleFeedbackData = (e, i) => {
    let {feedbackList} = this.state;
    feedbackList[i][e.target.name] = e.target.value
    this.setState({ feedbackList })
  }

  setStudentList = (list)=>{
    this.setState({ApplicationstudentList : list});
  }
  getOfferDetails = async()=>{
    let {applicationstudentList} = this.state,
    {application , campus_company_id , processId}=this.props;
    let roundData = this.getRoundData(application);
    let studentIdAray = [];
    roundData['applied'].forEach(student=>{
      studentIdAray.push(student.student_id);
    })
    try{
      let offerResp = await Auth.Offer.getOffer({student_id : studentIdAray , campus_company_id : campus_company_id , process_id : processId});
      this.setState({offerDetails : offerResp.data});
    }
    catch(e){
      console.log(e);
    }
  }

  render() {
    let { application, profile, shortlistVerification, company_name, companies, campus_company_id, processId } = this.props,
      {isOpenAppliedOption, uploadedFile,uploadedEmailFile, feedbackList, success, feedbackModal, currentPage, query, selected, shortListCount, selectAll, rowsPerPage, applicationModal, error, isLoading, shortlistModal, shareModal, roundId, addMilestoneModal, deleteRoundModal, is_hotlisting, isShareApplicant, addStudentModal, addOffersModal , closeApplicationModal,offerDetails } = this.state,
      roundData = this.getRoundData(application),
      roundDataIndex = Object.keys(roundData).indexOf("R-" + roundId) - 1,
      { role } = store.getState(),
      round = application.rounds ? application.rounds.filter(r => r.application_round_id === roundId)[0] : null
    let config = { hotlisting: {} };
    let length = rowsPerPage * currentPage;
    let dataRound = (roundDataIndex > -1) ? roundData[Object.keys(roundData)[roundDataIndex]] : [];
    //start : for mark buttons select All/Placed/Unplaced
    var placedStudentApplicationId = dataRound.filter(s => s.status === "Placed").map(s => s.student_application_id);
    var unplacedStudentApplicationId = dataRound.filter(s => s.status === "Unplaced").map(s => s.student_application_id);
    var selectedState = null;
    var selectedIds = Object.keys(selected).filter(id => !!selected[id]);
    if(selectedIds.length){
      if(dataRound.length === selectedIds.length)
        selectedState = "ALL";
      else if(selectedIds.every(r=> placedStudentApplicationId.includes(parseInt(r))) && selectedIds.length === placedStudentApplicationId.length)
        selectedState = "PLACED";
      else if(selectedIds.every(r=> unplacedStudentApplicationId.includes(parseInt(r))) && selectedIds.length === unplacedStudentApplicationId.length)
        selectedState = "UNPLACED";
      }
    //end : for mark buttons select All/Placed/Unplaced
    dataRound = dataRound.filter(st => {
      let name = getFullName(st.student)
      if (name.toLowerCase().includes(query.toLowerCase()) || st.student.roll_no.toLowerCase().includes(query.toLowerCase()))
        return st;
    })
    return (
      <div className="processes__application__detail">
        <Loader show={isLoading} />
        <Row noGutters className="justify-content-between">
          <Col align="center">
          </Col>
          {/* <Col xs="auto">
            <Button onClick={() => this.toggleState("addMilestoneModal")} className="r-heading-7 px-0"><i className="fi flaticon-plus-1 mr-2" />New</Button>
          </Col> */}
        </Row>
        <Row noGutters>

          <Col xs={5} className="pr-3">
            <Row>
              <Col>
                <RoundShortlistOverview application={application} roundData={roundData} getRoundData={this.getRoundData} />
              </Col>
            </Row>
          </Col>
          <Col xs={2}>
            <Row>
              <Col xs={12}>
                <div className="processes__milestone">
                  <h3 className="r-heading-4 r-text--light">Application Rounds</h3>
                  <div className="processes__milestone__item">
                    <Row noGutters className="px-2 align-items-end">
                      <Col>
                        <span className="r-subheading-3">Applications</span>
                      </Col>
                      {!shortlistVerification && <Col xs="auto">
                        <Button onClick={() => this.toggleState("applicationModal")}><i className="fi flaticon-edit" /></Button>
                      </Col>}
                    </Row>

                  {application.publish_status ? <a href={ENDPOINTS.DOWNLOAD.ELIGIBLE_STUDENT+"?application_id="+application.application_id} target="_blank"> <Button title="download eligible students" color="link"  className="float-right mr-2"><i className="fi flaticon-file" /></Button> </a> : ""}

                    {application.published_on && <p className="r-text--sm px-2">{moment(application.published_on).format("Do MMMM YYYY")}</p>}
                    <Row noGutters className="px-2 mb-3">
                      {/*<Col>
                                              <span className="r-subheading-4">{application.publish_status ? "Opened" : "Not Open"}</span>
                                              {
                                                ((this.props.role.hasPermission.processApplicationPublish) && (this.props.role.hasPermission.processApplicationPublish.permission_access.create === 1) && (application.publish_status === 0)) ? <Switch isOn={application.publish_status} handleToggle={(e) => this.checkPublish(application.publish_status)} /> : null
                                              }
                                              {(addStudentModal) ? <UnblockStudents publishApplication={this.publishApplication} addStudentModal={addStudentModal} application_id={this.props.application.application_id} process_id={this.props.processId} toggle={() => this.toggleState("addStudentModal")} /> : null}
                                            </Col>*/}
                    </Row>
                    {/* <div className="processes__milestone__desc">Eligibility Criteria</div>
                    <div className="processes__milestone__desc">Job Description</div>
                    <div className="processes__milestone__desc">Application Format</div> */}
                  </div>
                  <div className="processes__milestone__item">

                    <Row noGutters className="align-items-end">
                      {/*<Col>
                                              <Button className="float-right" onClick={this.toggleShareApplicant}>
                                                <i className="fi flaticon-mail"></i>
                                              </Button>
                                            </Col>*/}
                      {
                        isShareApplicant ?
                          <ShareApplicant
                            toggleShareApplicant={this.toggleShareApplicant}
                            isShareApplicant={isShareApplicant}
                            roundId={0}
                            applicationId={application.application_id}
                            application={application}
                            recruiter={this.props.recruiter}
                          />
                          :
                          null
                      }
                    </Row>

                    <Row noGutters className="align-items-end ">

                      <Col>
                        <h4 className="r-heading-2 m-0">{roundData["applied"].length}</h4>
                        <span className="r-subheading-3">Applied</span>
                      </Col>
                      <Col xs="auto">
                         <Button onClick={() => this.setState({ isOpenAppliedOption: true })}><i className="fi flaticon-plus" /></Button>

                        {/*
                          roundData["applied"].length ?
                            <DownloadCv
                              roundData={roundData["applied"]}
                              application={application}
                              role_name={profile.role_name}
                              company_name={company_name}
                            />
                            :
                            ""
                        */}
                      </Col>
                    </Row>
                  </div>

                  <Modal centered size="lg" isOpen={isOpenAppliedOption} toggle={() => this.setState({ isOpenAppliedOption: false })}>
                      <ModalHeader> <h4>Apply Student</h4> </ModalHeader>
                      <ModalBody> <MultipleStudentApply  handleDataChange={this.props.handleDataChange} close={() => this.setState({ isOpenAppliedOption: false })} applicationData = {this.props.application}/> </ModalBody>
 
                    </Modal>

                  <div className="processes__milestone__item">
                    {/*!shortlistVerification && <div onClick={() => this.shareData(0)} className={"processes__milestone__share" + (application.shortlist_release_status >= 2 ? " processes__milestone__share--disabled" : "")}>
                      <img src="/images/left-arrow.png" alt="" />
                      <span>{application.shortlist_release_status < 2  ? "Click to Share" : "Shared"}</span>
                    </div> */}
                    <Row noGutters className="align-items-end">
                      <Col>
                        <h4 className="r-heading-2 m-0">{roundData["R-0"].length}</h4>
                      </Col>
                      {!shortlistVerification && <Col className="ml-2" xs="auto">
                        {(application.shortlist_release_status < 2) ? <div><Button id="shareid" onClick={() => this.shareData(0)}><img src={share} /></Button>
                          <UncontrolledTooltip placement="bottom" target="shareid" className="r-bg--light">
                            Send application shortlist annoucment to students
                            </UncontrolledTooltip></div> : 'Shared'}
                      </Col>}
                    </Row>
                    <Row noGutters className="align-items-end">
                      <Col>
                        <span className="r-subheading-3">Initial shortlist</span>
                      </Col>
                      <Col xs="auto">
                        <Button onClick={() => this.getStudentApplicationRound(0)}><i className="fi flaticon-edit" /></Button>
                      </Col>
                    </Row>
                  </div>
                  {
                    application.rounds && application.rounds.map(round => {

                      return (
                        <div key={round.application_round_id} className="processes__milestone__item">
                          {/*(!shortlistVerification && roundData["R-" + round.application_round_id].length>0) && <div onClick={() => this.shareData(round.application_round_id)} className={"processes__milestone__share" + (round.shortlist_release_status >= 2 ? " processes__milestone__share--disabled" : "")}>
                            <img src="/images/left-arrow.png" alt="" />
                            <span>{round.shortlist_release_status < 2 ?  "Click to Share" :"Shared"}</span>
                          </div> */}
                          <Row noGutters className="align-items-end">
                            <Col>
                              <h4 className="r-heading-2 m-0">{roundData["R-" + round.application_round_id].length}</h4>
                            </Col>
                            <Col className="ml-2" xs="auto">
                              <Button onClick={() => this.setState({isOpenRoundQuestion : round})} className="r-text--sm" color="blue-3"><img alt="Questions" width={18} src="/images/questions.png"/></Button>

                              {(!shortlistVerification && roundData["R-" + round.application_round_id].length > 0) &&
                              (
                                (round.shortlist_release_status < 2) ? <div><Button id="share" onClick={() => this.shareData(round.application_round_id)}><img src={share} /></Button>
                                <UncontrolledTooltip placement="bottom" target="share" className="r-bg--light">
                                  Send application shortlist annoucment to students
                                </UncontrolledTooltip></div> : 'Shared')
                              }
                            </Col>
                          </Row>
                          <Row noGutters className="align-items-end">
                            <Col>
                              <span className="r-subheading-3">{round.round_name}</span>
                            </Col>
                            {(!shortlistVerification || (round.shortlist_release_status > 0)) && 
                            <Col xs="auto">
                              <Button onClick={() => this.getStudentApplicationRound(round.application_round_id)}><i className="fi flaticon-edit" /></Button>
                            </Col>}
                            {!shortlistVerification && <Col className="ml-2" xs="auto">
                              <Button onClick={() => this.setState({ deleteRoundId: round.application_round_id, deleteRoundModal: true })}><i className="fi flaticon-bin" /></Button>
                            </Col>}
                          </Row>
                        </div>
                      )
                    })
                  }
                  {!shortlistVerification && (
                    <Aux>
                      <div className="processes__milestone__item r-bg--light border-bottom">
                        <Row noGutters className="justify-content-center">
                          <Col xs="auto">
                            <Button onClick={() => this.toggleState("addMilestoneModal")} className="add-btn"><i className="fi flaticon-plus-1 mr-2" />New Round</Button>
                          </Col>
                        </Row>
                      </div>
                      <div className="mt-3 text-center">
                        <h3 className="r-heading-4 r-text--light">Offers & Feedback</h3>
                      </div>
                      <div className="processes__milestone__item r-bg--light">
                        <Row noGutters className="justify-content-center">
                          <Col xs={12}>
                            <Button onClick={() => this.toggleState("addOffersModal")} className="add-btn w-100"><i className="fi flaticon-plus-1 mr-2" />Offers</Button>
                          </Col>
                        </Row>
                      </div>
                      {application.is_recruitment_closed == 0 ? 
                          <div className="processes__milestone__item r-bg--light">
                            <Row noGutters className="justify-content-center">
                              <Col xs={12}>
                                <Button onClick={() => this.toggleState("closeApplicationModal")} className="add-btn w-100"><i className=" fi flaticon-plus-1 d-inline-block rotate--45 mr-2" />Close</Button>
                              </Col>
                            </Row>
                          </div>
                        : "" }

                      <div className="processes__milestone__item r-bg--light">
                        <Row noGutters className="justify-content-center">
                          <Col xs={12}>
                            <Button onClick={() => this.toggleState("feedbackModal")} className="add-btn w-100"><i className="fi flaticon-plus-1 mr-2" />Feedback</Button>
                          </Col>
                        </Row>
                      </div>
                    </Aux>)}
                </div>
                <Modal onOpened={()=>this.setState({feedbackList:[{name:"",email:""}], success: null})} size="m" zIndex="99999" isOpen={feedbackModal} toggle={() => {this.setState({success: null});this.toggleState("feedbackModal")}}>
                  <ModalBody>
                    {success!==null && <p>{success}</p>}

                    <Row>
                      <Col className="ml-2 mt-2">
                      <h4 className="r-subheading-4 pb-3">
                         This feature triggers an email to the recruiter email id shared. The feedback shared by the recruiter when submitted will be available in the Companies tab. You can view it by clicking on the Placement Drives the Company is mapped to.
                      </h4>
                      </Col>
                    </Row>
                   

                    <Button className="r-btn--no-radius" color="brandB" onClick={this.addEmailsToFeedback}><i className="fi flaticon-plus-1 mr-2" />Add Emails</Button>
                    <form className="mt-2" onSubmit={this.sendFeedback}>
                      {feedbackList.map((contact,i) => {
                        return (
                          <Row form key={i} className={"mb-2 pb-3" + (i!==feedbackList.length-1?" border-bottom":"")}>
                            <Col xs={12}>
                              <Row form className="mb-1">
                                <Col>
                                  <Row noGutters className="justify-content-between">
                                    <Col xs="auto">
                                      <label><span className="r-text--red-1">*</span>Name</label>
                                    </Col>
                                    <Col xs="auto">
                                    <Button type="button" onClick={()=>this.removeEmailFromFeedback(i)} className="r-text--sm">Remove</Button>
                                    </Col>
                                  </Row>
                                  <InputField className="w-100" onChange={(e)=>this.handleFeedbackData(e,i)} value={contact.name} placeholder="Enter Name" required type="text" name="name" />
                                </Col>
                              </Row>
                              <Row form>
                                <Col>
                                  <label><span className="r-text--red-1">*</span>Email</label>
                                  <InputField title="Must be a valid email" className="w-100" onChange={(e)=>this.handleFeedbackData(e,i)} value={contact.email} pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))' placeholder="Enter Email" required type="text" name="email" />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                      })}
                      {feedbackList.length>0 && <Button className="r-btn--no-radius" color="dark">Send</Button>}
                    </form>
                  </ModalBody>
                </Modal>



                <Modal centered size="lg" zIndex="99999" isOpen={this.state.isOpenRoundQuestion} toggle={() => this.setState({isOpenRoundQuestion: false})}>
                  <ModalHeader>Shortlist Questions</ModalHeader>
                  <ModalBody>
                    {!!this.state.isOpenRoundQuestion && <ShortlistQuestions roundData={this.state.isOpenRoundQuestion} />}
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={() => this.setState({isOpenRoundQuestion : false})} color="dark">Close</Button>
                  </ModalFooter>
                </Modal>


                <Modal size="xl" zIndex="99999" isOpen={addOffersModal} onOpened={()=>this.getOfferDetails()} toggle={() => this.toggleState("addOffersModal")}>
                  <ModalBody>
                    <AddOffer offerDetails={offerDetails} applicants={roundData["applied"] || []} handleOfferModal={this.handleOfferModal} companyList={companies} data={{
                      application_id: application.application_id,
                      campus_company_id,
                      process_id: processId,
                      designation: application.designation,
                      location: application.location,
                      process_company_profile_id: application.process_company_profile_id,
                      ctc_value: application.offer_amount_min || application.stipend_value,
                      currency: application.offer_currency,
                      offer_status: 1
                    }} />
                  </ModalBody>
                </Modal>

                <Modal size="md" zIndex="99999" className="modal--centered" isOpen={closeApplicationModal} toggle={() => this.toggleState("closeApplicationModal")}>
                  <ModalHeader>Application Close</ModalHeader>
                  <form onSubmit={this.onCloseApplication}>
                      <ModalBody>
                        <h4>Are you sure you want to close recruitement process for this application ?</h4>
                      </ModalBody>
                      <ModalFooter className="justify-content-between">
                        <div>
                          <span>
                            <input className="mr-2" name="generatePost" type="checkbox" defaultChecked={true} />Auto generate Feed
                          </span>
                        </div>
                        <div>
                          <Button type="button" onClick={() => this.toggleState("closeApplicationModal")}>Cancel</Button>
                          <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
                        </div>
                      </ModalFooter>
                  </form>
                </Modal>

                <Modal className="modal--centered" size="sm" zIndex="99999" isOpen={shareModal} toggle={() => this.toggleState("shareModal")}>
                  <ModalBody>
                    <span className="modal__title r-heading-6 mb-2">Update</span>
                    <p>Share shortlist data?</p>
                    <div className="text-right">
                      <span className="mr-3"><input type="checkbox" className="mr-2" checked={this.state.generatePost} onChange={() => this.setState({generatePost : !this.state.generatePost})} />Auto Generate Feed</span>
                      <Button onClick={this.submitShortlist} className="r-btn--no-radius" color="dark">Save</Button>
                    </div>
                  </ModalBody>
                </Modal>
                <Modal size="sm" zIndex="99999" isOpen={addMilestoneModal} toggle={() => this.toggleState("addMilestoneModal")}>
                  <ModalBody>
                    <span className="modal__title r-heading-6">Add Round</span>
                    <form className="mt-3" onSubmit={this.addMilestone}>
                      
                    <label className="r-subheading-4">Round Type</label>
                      <Select name="round_type" defaultValue="GD" onChange={(e) => {document.getElementById("round_name").value = CONSTANTS.ROUND_TYPES[e.target.value]}}>
                        {
                          Object.keys(CONSTANTS.ROUND_TYPES).map(data =>
                            <option key={data} value={data}>{CONSTANTS.ROUND_TYPES[data]}</option>
                          )
                        }
                      </Select>
                      <label className="r-subheading-4 mt-2">Round Name</label>
                      <InputField id="round_name" required name="round_name" defaultValue="Group Discussion" className="w-100" type="text" placeholder="Enter Round Name" />
                      
                      {config.hotlisting.status ?
                        <div>
                          <label className="mt-2 r-subheading-4">Hotlisting</label>
                          <Select name="is_hotlisting" value={is_hotlisting || '0'} onChange={(e) => this.setState({ is_hotlisting: e.target.value })}>
                            <option value="0">No</option>
                            <option value="1">Yes</option>
                          </Select>
                        </div> : ""
                      }
                      {is_hotlisting == '1' ?
                        <div>
                          <span className="r-subheading-4">Hotlisting Deadline</span>
                          <SingleDatePicker
                            value={this.state.datepickerValue}
                            onDateChange={(date) => { this.setState({ datepickerValue: date }) }}
                          />
                        </div>
                        : null
                      }
                      <div className="text-right mt-3">
                        <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
                      </div>

                    </form>
                  </ModalBody>
                </Modal>
                <Modal className="modal--centered" size="sm" zIndex="99999" isOpen={deleteRoundModal} toggle={() => this.toggleState("deleteRoundModal")}>
                  <ModalBody>
                    <span className="r-heading-6">Are you sure?</span>
                    <form className="mt-3" onSubmit={this.deleteMilestone}>
                      <div className="text-right mt-3">
                        <Button type="button" onClick={() => this.toggleState("deleteRoundModal")}>Cancel</Button>
                        <Button type="submit" color="dark" className="r-text--sm r-btn--no-radius">Delete</Button>
                      </div>

                    </form>
                  </ModalBody>
                </Modal>
                <Modal zIndex="99999" size="md" isOpen={shortlistModal} toggle={() => {this.setState({success: null});this.toggleState("shortlistModal")}}>
                  <ModalBody>
                    <Nav tabs className="mb-3">
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '1' })}
                          onClick={() => { this.setState({ activeTab: '1' }) }}
                          style={{ cursor: "pointer" }}
                        >
                          Shortlist Students
                              </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.setState({ activeTab: '2' }) }}
                          style={{ cursor: "pointer" }}
                        >
                          Upload Shortlist
                              </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.setState({ activeTab: '3' }) }}
                          style={{ cursor: "pointer" }}
                        >
                          Email shortlisted candidates
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                        {roundId !== null &&
                          <Aux>
                            <span className="r-heading-3">{roundId === 0 ? "Initial Shortlist" : application.rounds.filter(round => round.application_round_id === roundId)[0].round_name}</span>
                            <Row noGutters className="justify-content-between my-2">
                              <Col xs="5">
                                <span className="r-heading-4">Total shortlisted students - {shortListCount}</span>
                              </Col>
                              <Col xs="4">
                                <InputField className="w-100" placeholder="Search Name/Roll No" name="search" value={query} onChange={this.onSearch} />
                              </Col>
                            </Row>
                            <Table className="processes__table__2">
                              <thead>
                                <tr>
                                  <th style={{ width: 10 }}></th>
                                  <th>Name</th>
                                  <th>Roll No.</th>
                                  <th>Status</th>

                                  <th>
                                    {/* <input type="checkbox"
                                      checked={selectAll === 1}
                                      style={{ marginRight: '10px' }}
                                      name="selectAll"
                                      ref={input => {
                                        if (input) {
                                          input.indeterminate = selectAll === 2;
                                        }
                                      }}
                                      onChange={() => this.toggleSelectAll()} /> */}
                                    Select
                                    <div style={{fontSize: 9}}>
                                      <Button color={selectedState ==="PLACED" ? "blue-3" : "blue"} className="px-2 py-1" style={{borderRadius : 10}} onClick={() => this.toggleSelectAll("PLACED")}>Placed</Button>
                                      <Button color={selectedState ==="ALL" ? "blue-3" : "blue"} className="px-2 py-1 mx-2" style={{borderRadius : 10}} onClick={() => this.toggleSelectAll("ALL")}>All</Button>
                                      <Button color={selectedState ==="UNPLACED" ? "blue-3" : "blue"} className="px-2 py-1" style={{borderRadius : 10}} onClick={() => this.toggleSelectAll("UNPLACED")}>Unplaced</Button>
                                    </div>
                                </th>
                                  {(round && round.is_hotlisting === 1) && <th>Hotlist Status</th>}
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  dataRound.slice(parseInt(currentPage) * parseInt(rowsPerPage), parseInt(currentPage) * parseInt(rowsPerPage) + parseInt(rowsPerPage)).map((studentApplication, i) => {
                                    let { student } = studentApplication;
                                    length = length + 1;
                                    let studentShortlist = studentApplication.shortlisting_data.filter(data => (data.application_round_id === roundId && data.type === "S"))[0]
                                    let shortlistStatus = false;
                                    if (studentShortlist && studentShortlist.shortlist_status === 1) {
                                      shortlistStatus = true
                                    }
                                    let studentHotlist = studentApplication.shortlisting_data.filter(data => (data.application_round_id === roundId && data.type === "H"))[0]
                                    let hotlistStatus = false;
                                    if (studentHotlist && studentHotlist.shortlist_status === 1) {
                                      hotlistStatus = true
                                    }
                                    return (
                                      <tr key={i}>
                                        <td>{length}</td>
                                        <td>
                                          {getFullName(student)}
                                        </td>
                                        <td>
                                          {student.roll_no}
                                        </td>
                                        <td>
                                          {studentApplication.status}
                                        </td>
                                        <td style={{paddingLeft: 65}}><input name={`shortlist_status_${application.application_id}`} checked={selected[studentApplication.student_application_id] === true} type="checkbox" value={studentApplication.student_application_id} onChange={() => this.selectRow(studentApplication.student_application_id)} /></td>
                                        {(round && round.is_hotlisting === 1) && <td><input name={`hotlist_status_${application.application_id}`} defaultChecked={hotlistStatus} type="checkbox" value={studentApplication.student_application_id} /></td>}
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </Table>
                            <Row className="justify-content-between align-items-center mx-0" >
                              <Col xs="auto" className="">
                                <Row className="justify-content-between align-items-center">
                                  <span className="px-1 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>Total students are {dataRound.length}</span>
                                  <span className="px-3 d-inline-block r-subheading-4 " style={{ fontSize: "15px" }}>View</span>
                                  <div className="r-dropdown border-0">
                                    <select value={rowsPerPage} name="rowsPerPage" className="r-dropdown__select " style={{ backgroundColor: "#f5f5f5" }} onChange={(e) => { this.setState({ [e.target.name]: e.target.value, currentPage: 0 }) }}>
                                      <option value="10" >10</option>
                                      <option value="15">15</option>
                                      <option value="20" >20</option>
                                      <option value="25">25</option>
                                      <option value="50" >50</option>
                                      <option value="100">100</option>
                                    </select>
                                    <i className="fi flaticon-arrow" />
                                  </div>
                                </Row>
                              </Col>
                              <Col xs="auto">
                                <PaginationComponent
                                  totalItems={dataRound.length}
                                  pageSize={rowsPerPage}
                                  onSelect={this.handleSelected}
                                  maxPaginationNumbers={3}
                                  activePage={currentPage + 1}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={"auto"}>
                                <button type="button"
                                className="mr-3 r-btn r-btn--dark r-btn--no-radius"
                                color="dark r-btn--no-radius"
                                onClick={() => this.handledDownloadSendMail()}
                                >
                                  Send CV's to email
                                </button>
                                {success!==null && <p clas>{success  || "Success"}</p>}
                              </Col>
                              <Col  xs={"auto"}>
                                <Button onClick={() => this.setState({ downloadCSVModal: !this.state.downloadCSVModal})} color="dark r-btn--no-radius" className="mr-3"><i className="fi flaticon-file mr-3" />EXCEL</Button>
                                <div className="excel_setting">
                                  <Modal zIndex="99999" className="modal--centered excel_setting" isOpen={this.state.downloadCSVModal} toggle={() => this.setState({ downloadCSVModal: false })}>
                                  <ModalBody>
                                    <Button color="dark" onClick={()=>this.handledDownloadCSV()}>Download</Button>
                                    <Link to={"/pt/Configurations/excel"}><Button className="r-text--dark r-bg--light border ml-3">Change Excel Configurations</Button></Link>
                                  </ModalBody>
                                  </Modal>
                                </div>
                              </Col>
                              <Col className="text-right">
                                {/* <Button color="dark" className="r-text--sm r-btn--no-radius mr-3">Save and share<i className="fi flaticon-arrow" /></Button> */}
                                <Button onClick={this.handleShortistSave} color="dark" className="r-text--sm r-btn--no-radius">Save</Button>
                              </Col>

                            </Row>
                          </Aux>
                        }
                      </TabPane>
                      <TabPane tabId="2">
                        {roundId !== null &&
                          <Aux>
                            <Row noGutters>
                              <Col>
                                <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                                  <div className="border text-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                    {(uploadedFile !== null) ? uploadedFile.name : "Upload File"}
                                    <i className=" r-text--xl fi flaticon-upload r-text--grey-4 ml-3" style={{ fontSize: "16px" }} />
                                  </div>
                                  <input id="file-input" onChange={(e) => this.handleUploadFile(e)} type="file" style={{ display: "none" }} />
                                </label>
                                <a className="d-block my-2" href="/template-shortlist.xlsx" download>Download excel template</a>
                                <div className="text-right">
                                  <Button square='true' color="dark" onClick={this.uploadShortlist}>Save</Button>
                                </div>
                              </Col>
                            </Row>
                          </Aux>
                        }
                      </TabPane>

                      <TabPane tabId="3">
                        <Aux>
                          <Row noGutters>
                                <Col>
                                  <label htmlFor="file-input2" style={{ cursor: "pointer" }}>
                                    <div className="border text-center font-weight-normal mx-3 py-1  px-3  " color="outline-dark-v">
                                      {(uploadedEmailFile !== null) ? uploadedEmailFile.name : "Upload"}
                                      <i className=" r-text--xl fi flaticon-upload r-text--grey-4 ml-3" style={{ fontSize: "16px" }} />
                                    </div>
                                    <input id="file-input2" onChange={(e) => this.handleUploadEmailFile(e)} type="file" style={{ display: "none" }} />
                                  </label>
                                  <p className="mt-3 mb-3 ml-3">Each student will receive separate individual emails with the details in the excel</p>
                                  <a className="d-block my-2 ml-3" href="https://campus.frontend.s3.ap-south-1.amazonaws.com/shortlist_email_credential_sample.xlsx" download>Download excel template</a>
                                  <div className="text-right">
                                    <Button square='true' color="dark" onClick={this.uploadShortlistEmail}>Send</Button>
                                  </div>
                                </Col>
                            </Row>
                        </Aux>
                      </TabPane>
                    </TabContent>

                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          </Col>
          <Col xs={5} className="pl-3 d-flex flex-column">
            { application.publish_status ? 
              <Row  className="processes__application__post  mb-3 align-items-center">
              <Col xs={6} >
                <p className="r-subheading-3">{company_name}</p>
                <div className="r-text--sm r-text--grey">
                  <span>download Eligible data </span>
                </div>
              </Col>
              <Col xs={6} >
                <Row  className="align-items-center">
                       <Col  xs={6}>
                           
                        </Col>
                    <Col xs={6}>
                      <a href={ENDPOINTS.DOWNLOAD.ELIGIBLE_STUDENT+"?application_id="+application.application_id} target="_blank">  
                        <Button className="w-100  r-text--light r-bg--blue-3 border-0">
                                <span className="r-heading-4 d-block"><i className="fi flaticon-file" /></span>
                                <span className="r-subheading-4">Download</span>
                        </Button>
                      </a>
                      </Col>
                  </Row>
              </Col>
            </Row> : ""
            }{ application.publish_status ? 
              <Row  className="processes__application__post  mb-3 align-items-center">
              <Col xs={6} >
                <p className="r-subheading-3">{company_name}</p>
                <div className="r-text--sm r-text--grey">
                    {this.props.recruiter ? "Recruiter Exist for this Company" : "Add Recruiter to send application data"}
                </div>
              </Col>
              <Col xs={6} >
                <Row  className="align-items-center">
                       <Col  xs={6}>
                           
                        </Col>
                    {
                    !this.props.recruiter ? <Col xs={6}>
                        <Button className="w-100  r-text--light r-bg--blue-3 border-0">
                                <span className="r-subheading-4" onClick={() => this.toggleAddRecuriter()}>Create Recruiter Access</span>
                        </Button>

                        <Modal className="modal-dialog-centered" isOpen={this.state.isOpenAddRecuriter} toggle={this.toggleAddRecuriter} >
                          <ModalBody>
                            <AddRecruiter company_id={this.props.company_id} onSuccess={console.log} />
                          </ModalBody>
                        </Modal>
                      </Col>
                      : 
                      <Col xs={6}>
                        <Button className="w-100  r-text--light r-bg--blue-3 border-0" onClick={() => this.toggleShareApplicant()}>
                                <span className="r-subheading-4">Send Data To Recruiter</span>
                        </Button>
                      </Col>
                    }
                  </Row>
              </Col>
            </Row> : ""
            }
            {
              roundData["applied"].length ?
                <DownloadCv
                  roundData={roundData["applied"]}
                  application={application}
                  role_name={profile.role_name}
                  company_name={company_name}
                  toggleShareApplicant={this.toggleShareApplicant}
                />
                :
                ""
            }
            {/* <h3 className="r-heading-4">Company</h3>
            <div className="processes__application__company">
              <div className="processes__application__company__contact active">
                <span className="r-subheading-3">Sunair Raj</span>
                <p className="r-text--sm">HR Manager</p>
              </div>
              <div className="processes__application__company__contact">
                <span className="r-subheading-3">Sunair Raj</span>
                <p className="r-text--sm">HR Manager</p>
              </div>
            </div>
            <h4 className="r-subheading-4">Message</h4>
            <div className="processes__chatbox">
              <div className="processes__chatbox__messages"></div>
              <InputField type="text" className="w-100" placeholder="Type here" />
              <div className="processes__chatbox__actions">
                <InputField type="checkbox" value="Send as mail" />
                <Button color="dark" className="r-btn--no-radius ml-2">Send</Button>
              </div>
            </div> */}
          </Col>
        </Row>
        <ApplicationModal handleDataChange={this.props.handleDataChange} applicationModal={applicationModal} application={application} toggleModal={this.toggleState} profile={profile} role={this.props.role} processStudent={this.props.processStudent} />
        {/* <Modal zIndex="99999" size="md" isOpen={postModal} toggle={() => this.toggleState("postModal")}>
          <ModalBody>
            <Row noGutters className="border-bottom">
              <Col xs={12}>
                <span className="modal__post__header">Post</span>
                 <span className="modal__post__header">Email</span>
              </Col>
            </Row>
            <div className="modal__post">
              <Row noGutters className="align-items-center py-4">
                <Col xs={12}>
                  <div className="post__header">
                    <img src="/images/dummy_company.png" alt=""/>
                    <div>
                      <span className="r-heading-4">Placement Team</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="modal__post__attachment">
                Add an <span><i className="fi flaticon-art" /> | <i className="fi flaticon-multimedia" /></span>
              </div>
              <div className="modal__post__fields">
                <InputField type="text" placeholder="Enter post title" />
                <RTE placeholder="Enter Post content here" />
              </div>
              <div className="text-right">
                <Button className="r-btn--no-radius mt-3" color="dark">Post</Button>
              </div>
            </div>
          </ModalBody>
        </Modal> */}
        <Errorbar showBar={error.show} message={error.message} onClose={this.closeError} />

      </div>
    )
  }
}

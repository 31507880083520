import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader } from '../../../global/Loader';
import { CONSTANTS } from '../../../../constants';
import Button from '../../../global/Button';
import { Auth } from '../../../../actions/Auth';
import store from '../../../../store';
import { Errorbar } from '../../../global/errorbar';

export default  class ShareApplicant extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen : false,
            errorbar : false,
            isLoading: false,
            message: ''
        }
    
    }

    


    shareApplicantLink= async (e)=>{
        e.preventDefault()
        e.persist()

        let {email} = e.target
        let {roundId, applicationId, application} =this.props

        // if(email == ''){
        //     console.log('inside email')
        //     this.setState({errorbar: true, message:"Please enter email address"});
        // } 
    
        let formData = {
            recruiter_id: this.props.recruiter && this.props.recruiter.recruiter_id,
            application_id: applicationId,
            application_round_id: roundId,
            invited_by: application.invited_by
        }

        this.toggleLoading(true)

        try {

            let apiRespSendLink  = await Auth.SendLink.toCompany(formData)     
            this.setState({message: `Applicants data shared with recruiter!`})
            e.target.reset()

        } catch (e) {
            this.setState({errorbar: true, message:"Sorry something went wrong, please try again"});            
        }

        this.toggleLoading(false)
    }

    toggleModal=()=>{

        this.props.toggleShareApplicant()
    }

    closebar = () =>{
        this.setState({errorbar: false});
    }

    toggleLoading = (isLoading) =>{
        this.setState({
            isLoading
        })
    }


    render() {
      

        let {isShareApplicant} = this.props

        let {message, errorbar, isLoading} = this.state

        return (

            <div className="send-to-company"> 
                <Loader show={isLoading} />  
                <Modal zIndex={99999} className="modal-dialog-centered" isOpen={isShareApplicant} toggle={this.toggleModal} >
                    <ModalHeader className="r-heading-6" toggle={this.toggleModal}>
                        Send to company        
                    </ModalHeader>                
                    <ModalBody>
                        {
                            message? <h3 className="r-heading-6 r-text--green-1">{message}</h3>  : null
                        }

                        <form onSubmit={this.shareApplicantLink}>
                            {/* <label className="r-heading-7">Enter Email</label>
                            <input type="email" className="w-100 r-inputfield" name="email" placeholder="Type email here" required={true}/>
                            <hr/> */}
                            <p>Are you sure you want to share application data with recruiter?</p>
                            <div className="float-right row">
                                <button type="submit" color="dark" className="r-btn--no-radius r-text--sm r-btn r-btn--dark">Send</button>
                                <button type="button" className="r-btn" onClick={this.toggleModal}>  Cancel</button>
                            </div>
                        </form>

                    </ModalBody>  
                </Modal>
                <Errorbar showBar={errorbar} onClose={this.closebar} message={message}/>
            </div>

        )

    }

}

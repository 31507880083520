import React, { Component } from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Progress from 'reactstrap/lib/Progress';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Loader } from '../../../global/Loader';
import Button from '../../../global/Button';
import { Auth } from '../../../../actions/Auth';
import { API_CONFIG , ENDPOINTS} from '../../../../constants/endpoints';
import Link from "react-router-dom/Link";
import { getFullName } from '../../../../actions';
import store from '../../../../store';
import Aux from '../../../global/AuxComp';
import Table from 'reactstrap/lib/Table';


export default class DownloadCv extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    modal: false,
    message: null,
    loader: false,
    errorbar: false,
    errorMessage: null,
    downloadCSVModal : false,
    jobData: null,
    selectedStudentId : this.props.roundData.map(data => data.student.student_id)
  }

  closebar = () => {
    this.setState({ errorbar: false });
  }

  toggleModal = async () => {
    await this.setState({ modal: !this.state.modal, message: null })
  }

  toggleLoading = (loader) => {
    this.setState({
      loader
    })
  }

  handledDownloadSendMail = async () => {
    let {application, role_name, company_name} = this.props
    let {selectedStudentId} = this.state
    let formData = {
      "scope":'application',
      "application_id": application.application_id,
      role_name,
      company_name,
    }
    if(selectedStudentId.length) formData.student_id = selectedStudentId;
    try {
      this.toggleLoading(true);
      let downloadResp = await Auth.Download.cvDownload(formData);
      await this.setState({ message: downloadResp.data.message });
      this.checkJob();
      this.toggleLoading(false)
    } catch (e) {
      console.log(e)
      this.setState({ errorMessage: 'Sorry something went wrong. Please try again' });
      this.setState({ errorbar: true });
    }
  }

  handledDownloadCSV = async() => {
    let {role_name, company_name} = this.props;
    let {selectedStudentId} = this.state;
    let studentId = {
      student_id : []
    };
    let { roundData } = this.props
    roundData.forEach(data => {
      studentId.student_id.push(data.student.student_id)
    });
    if(selectedStudentId.length) studentId.student_id = selectedStudentId;
    try{
          let csvResp = await Auth.Download.cvCSVDownload(studentId)
          const url = window.URL.createObjectURL(new Blob([csvResp.data]));
          const link = document.createElement('a');
          link.href = url;
          let fileName = company_name+'_'+role_name+'.csv';
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        catch(e){
          if(e.response && e.response.error){
            let message = e.response.data.error
            this.setState({ errorbar: true, message })
          }
          else{
            console.log(e);
          }
        }
    this.setState({ downloadCSVModal: !this.state.downloadCSVModal });
  }

  handleModalOpen = async() => {
    this.checkJob()
  }

  checkJob = async() =>{
    let {modal} = this.state,
    redux = store.getState();
    try {
      let jobResp = await Auth.Kue.search({ q: `${redux.user.id}-application-cv-download` });
      jobResp.data= jobResp.data.map(id=>{
        return parseInt(id)
      })
      if (jobResp.data[0]) {
        let detailsResp = await Auth.Kue.getJobDetails({ id: Math.max(...jobResp.data) })
        if (detailsResp.data.state !== "failed") {
          this.setState({ jobData: detailsResp.data })
          this.jobTimeout = setTimeout(() => {
            let { jobData } = this.state
            if (modal && jobData && jobData.state !== "complete")
              this.checkJob()
            else
              this.setState({message: null})
          }, 1000)
        } else {
          this.setState({jobData: null})
        }
      }
    } catch(e) {
      console.log(e)
    }
  }

  handleModalClose = () => {
    if(this.jobTimeout) clearTimeout(this.jobTimeout)
  }

  onSelectStudent = (e) => {
    let {selectedStudentId} = this.state;
    if(e.target.checked) selectedStudentId.push(parseInt(e.target.value));
    else {
      selectedStudentId.splice(selectedStudentId.indexOf(parseInt(e.target.value)), 1)
    }
    this.setState({selectedStudentId})
}
selectStudent = (type) => {
  let {roundData} = this.props;
  if(type === "PLACED"){
    this.setState({selectedStudentId : roundData.filter(s => s.status === "Placed").map(s => s.student.student_id)});
  }
  if(type === "UNPLACED"){
    this.setState({selectedStudentId : roundData.filter(s => s.status === "Unplaced").map(s => s.student.student_id)});
  }
  if(type === "ALL"){
    this.setState({selectedStudentId : roundData.map(s => s.student.student_id)});
  }
}
  render() {
    let { message, modal, jobData, selectedStudentId } = this.state;
    let { roundData,application ,role_name,company_name} = this.props;
    let binderInfo = application.is_multiple_role && application.application_binder ? application.application_binder[0] : {};
    var placedStudentApplicationId = roundData.filter(s => s.status === "Placed").map(s => s.student.student_id);
    var unplacedStudentApplicationId = roundData.filter(s => s.status === "Unplaced").map(s => s.student.student_id);
    var selectedState = null;
    if(selectedStudentId.length){
      if(roundData.length === selectedStudentId.length)
        selectedState = "ALL";
      else if(selectedStudentId.every(r=> placedStudentApplicationId.includes(parseInt(r))) && selectedStudentId.length === placedStudentApplicationId.length)
        selectedState = "PLACED";
      else if(selectedStudentId.every(r=> unplacedStudentApplicationId.includes(parseInt(r))) && selectedStudentId.length === unplacedStudentApplicationId.length)
        selectedState = "UNPLACED";
      }
    return (
      <div className="download ">
        <Loader show={this.state.loader} />
                    <Row  className="processes__application__post  mb-3 align-items-center">
                      <Col xs={6} >
                        <p className="r-subheading-3">{company_name}</p>
                        <div className="r-text--sm r-text--grey">
                          <span>download applicants data </span>
                        </div>
                      </Col>
                      <Col xs={6} >
                        <Row  className="align-items-center">
                               <Col  xs={6}>
                                   {/* {(application.invited_by==='RECRUITER') && <Button className="w-100 r-text--light r-bg--blue-3 border-0" onClick={this.props.toggleShareApplicant}>
                                            <span className="r-heading-4 d-block"><i className="fi flaticon-mail" /></span>
                                            <span className="r-subheading-4">Mail</span>
                                    </Button>} */}
                                </Col>
                            <Col xs={6}>
                              <Button className="w-100  r-text--light r-bg--blue-3 border-0" onClick={() => this.toggleModal()}>
                                      <span className="r-heading-4 d-block"><i className="fi flaticon-file" /></span>
                                      <span className="r-subheading-4">Download</span>
                              </Button>
                              </Col>
                          </Row>
                      </Col>
                    </Row>

        <Row>
          <Modal onClosed={this.handleModalClose} onOpened={this.handleModalOpen} zIndex="99999" size="md" className="modal-dialog-centered" isOpen={modal} toggle={() => this.toggleModal(false)} >
            <ModalHeader toggle={() => this.toggleModal(false)}>

              <p className="r-heading-6">Download Applicants Data</p>
            </ModalHeader>

            <ModalBody>

              <Row className="px-3">
                <Col xs={2}  className="r-bg--blue-1 py-2">
                  <h3 className="r-heading-6"> Roll No </h3>
                </Col>
                <Col xs={5} className="r-bg--blue-1 py-2">
                  <h3 className="r-heading-6"> Student Name  </h3>
                </Col>
                <Col xs={2} className="r-bg--blue-1 py-2">
                  <h3 className="r-heading-6">Status</h3>
                </Col>
                <Col xs={3} className="r-bg--blue-1 py-2" > 
                  <h3 className="r-heading-6">Select</h3>
                  <div style={{fontSize: 9}}>
                    <Button color={selectedState ==="PLACED" ? "blue-3" : "blue"} className="px-2 py-1" style={{borderRadius : 10}} onClick={() => this.selectStudent("PLACED")}>Placed</Button>
                    <Button color={selectedState ==="ALL" ? "blue-3" : "blue"} className="px-2 py-1 mx-2" style={{borderRadius : 10}} onClick={() => this.selectStudent("ALL")}>All</Button>
                    <Button color={selectedState ==="UNPLACED" ? "blue-3" : "blue"} className="px-2 py-1" style={{borderRadius : 10}} onClick={() => this.selectStudent("UNPLACED")}>Unplaced</Button>
                  </div>
                </Col>
              </Row>

               {

                roundData ?

                  roundData.map((data, i) => {
                    return (

                      <Row className='my-2' key={`download${i}`}>
                        <Col xs={2}>
                          {data.student.roll_no}
                        </Col>
                        <Col xs={5}>
                          {getFullName(data.student)}
                        </Col>
                        <Col xs={2}> 
                          {data.status}
                        </Col>
                        <Col xs={3}> 
                          <input type="checkbox" value={data.student.student_id} checked={selectedStudentId.indexOf(data.student.student_id) !== -1} onChange={this.onSelectStudent} style={{marginLeft : 50}}/>
                        </Col>
                      </Row>
                    )

                  })

                  :
                  null

              }

            </ModalBody>

            <ModalFooter className="d-block">
              {
                  <div className="download-action mb-2">
                    <a download href={ENDPOINTS.PROCESSES.DOWNLOAD_APPLICATION_DATA + "?application_id=" + this.props.application.application_id} className="mr-3 btn-profile-download r-btn r-btn--dark r-btn--no-radius text-white "
                      color="dark r-btn--no-radius" target="target _blank">
                      <i className="fi flaticon-file mr-3" />Answers
                    </a>
                    { binderInfo.is_preference ?
                    <a download href={ENDPOINTS.PROCESSES.DOWNLOAD_APPLICATION_PREFERENCE + "?process_id=" + this.props.application.process_id+'&application_binder_id='+this.props.application.application_binder_id} className="mr-3 btn-profile-download r-btn r-btn--dark r-btn--no-radius text-white "
                      color="dark r-btn--no-radius" target="target _blank">
                      <i className="fi flaticon-file mr-3" />Preference
                    </a> : null
                    }
                      <Button onClick={() => this.setState({ downloadCSVModal: !this.state.downloadCSVModal , modal: !this.state.modal, message: null})} color="dark r-btn--no-radius" className="mr-3"><i className="fi flaticon-file mr-3" />EXCEL</Button>
                    <button type="button"
                      className="mr-3 r-btn r-btn--dark r-btn--no-radius"
                      color="dark r-btn--no-radius"
                      disabled={(message||(jobData && jobData.state !== "complete"))?true: false}
                      onClick={() => this.handledDownloadSendMail()}
                    >
                      Send CV's to email
                            </button>
                    <Button type="button" onClick={() => this.toggleModal()}>Cancel</Button>
                  </div>
                  
              }
              {
                (jobData && jobData.state !== "complete") ? <div>
                <span>{jobData.progress}%</span>
                <Progress value={jobData.progress} />
                <span className="r-text--brandF r-text--sm">{jobData.error}</span>
              </div> : null
              }
              { message? 
                    <div className="card shadow-sm mt-2">
                      <div className='card-body'>
                        {message}
                      </div>
                    </div>: ""}
            </ModalFooter>
          </Modal>
          <div className="excel_setting">
          <Modal zIndex="99999" className="modal--centered excel_setting" isOpen={this.state.downloadCSVModal} toggle={() => this.setState({ downloadCSVModal: false })}>
          <ModalBody>
            <Button color="dark" onClick={()=>this.handledDownloadCSV()}>Download</Button>
            <Link to={"/pt/Configurations/excel"}><Button className="r-text--dark r-bg--light border ml-3">Change Excel Configurations</Button></Link>
          </ModalBody>
          </Modal>
          </div>
        </Row>

      </div>

    )
  }
}
